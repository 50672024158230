<template>
    <client-page>
        <sub-visual sh="시설 및 참여" tabActive="프로그램 안내" bg="/images/sub/visual/sv2.jpg"> </sub-visual>

        <section class="section--sm section--first section--last">
            <v-container>
                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold">스포츠 아카데미 프로그램</h3>
                </div>

                <v-img src="/images/common/scroll.png" max-width="290" class="d-block d-md-none mb-10 mx-auto" />
                <div class="table-responsive mb-50 mb-74">
                    <table class="client-table">
                        <thead>
                            <tr>
                                <th class="table-split">
                                    <div class="table-inner">서울본점</div>
                                </th>
                                <th class="table-split">
                                    <div class="table-inner">인천(청라)</div>
                                </th>
                                <th class="table-split">
                                    <div class="table-inner">하남</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="table-inner">재능기부 및 진로체험</div>
                                </td>
                                <td>
                                    <div class="table-inner">재능기부 및 진로체험</div>
                                </td>
                                <td>
                                    <div class="table-inner">재능기부</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table-inner">
                                        유소년 취미반 (야구, 축구) <br />
                                        주중반 <br />
                                        주말반
                                    </div>
                                </td>
                                <td>
                                    <div class="table-inner">
                                        유소년 취미반 (야구) <br />
                                        주중반 <br />
                                        주말반
                                    </div>
                                </td>
                                <td>
                                    <div class="table-inner">
                                        리틀 야구단 <br />
                                        취미반 / 선수반 모집
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table-inner">
                                        <p>
                                            종목 <strong>야구</strong> 문의하기 : 010-5823-2592 <br />
                                            종목 <strong>축구</strong> 문의하기 : 010-5060-8723
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div class="table-inner">
                                        <p>종목 <strong>야구</strong> 문의하기 : 010-8961-2389</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="table-inner">
                                        <p>종목 <strong>야구</strong> 문의하기 : 010-3716-3589</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <div class="table-inner">
                                        취미반은 초등학생 대상입니다.<br />
                                        주중 취미반 셔틀버스 일부운행 <br />
                                        주말 취미반은 셔틀버스를 운행하지 않습니다.
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <v-img src="/images/common/scroll.png" max-width="290" class="d-block d-md-none mb-10 mx-auto" />
                <div class="table-responsive">
                    <table class="client-table">
                        <colgroup>
                            <col style="width: 20%" />
                            <col style="width: 20%" />
                            <col style="width: 20%" />
                            <col style="width: 20%" />
                            <col style="width: 20%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <div class="table-inner">구분</div>
                                </th>
                                <th>
                                    <div class="table-inner">기간 및 횟수</div>
                                </th>
                                <th>
                                    <div class="table-inner">가격</div>
                                </th>
                                <th>
                                    <div class="table-inner">비고</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-point">
                                <th>
                                    <div class="table-inner">차상위계층청소년</div>
                                </th>
                                <td>
                                    <div class="table-inner">개인 혹은 단체</div>
                                </td>
                                <td>
                                    <div class="table-inner">월 4회</div>
                                </td>
                                <td>
                                    <div class="table-inner">스포츠바우처</div>
                                </td>
                                <td>
                                    <div class="table-inner">1인당 환산시 ₩95,000</div>
                                </td>
                            </tr>
                            <tr>
                                <th rowspan="2">
                                    <div class="table-inner">취미반</div>
                                </th>
                                <td rowspan="2">
                                    <div class="table-inner">주중, 주말</div>
                                </td>
                                <td>
                                    <div class="table-inner">월 4회</div>
                                </td>
                                <td>
                                    <div class="table-inner">₩140,000</div>
                                </td>
                                <td rowspan="2">
                                    <div class="table-inner">출연가격의 30% 차상위 계층 아이들의 스포츠 교육을 위한 기부 후원금 처리가 됩니다.</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table-inner">월 8회</div>
                                </td>
                                <td class="border-end">
                                    <div class="table-inner">₩200,000</div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="table-inner">사회인 레슨</div>
                                </th>
                                <td colspan="4">
                                    <div class="table-inner">방문 또는 이메일 문의</div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div class="table-inner">대관</div>
                                </th>
                                <td>
                                    <div class="table-inner">2시간 기준</div>
                                </td>
                                <td>
                                    <div class="table-inner">1회</div>
                                </td>
                                <td>
                                    <div class="table-inner">₩200,000</div>
                                </td>
                                <td>
                                    <div class="table-inner">0코치 + ₩100,000 추가</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
.table-split {
    width: calc(100% / 3);
}
</style>
