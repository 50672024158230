<template>
    <client-page>

        <sub-visual
        sh="문의하기"
        tabActive="문의하기"
        bg="/images/sub/visual/sv3.jpg">
        </sub-visual>

        <section class="section">
            <form-mail></form-mail>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import FormMail from "@/components/client/ko/demo/form-mail.vue"

export default {
    components: {
        ClientPage,
        SubVisual,
        FormMail
    },
};
</script>

<style lang="scss" scoped>

</style>