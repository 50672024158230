var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual-wrap mt-50 mt-lg-0 fade"
  }, [_c('div', {
    staticClass: "sub-visual pt-lg-100 pb-lg-60"
  }, [_c('div', {
    staticClass: "sub-visual-tit"
  }, [_c('v-container', [_c('h2', [_vm._v(_vm._s(_vm.sh))])])], 1), _c('div', {
    staticClass: "sub-visual-bg",
    style: 'background-image:url(' + _vm.bg + ');'
  })]), _c('v-container', [_vm.sh !== '문의하기' || '개인정보처리방침' || '개인정보 제3자 제공 동의' ? _c('client-gnb', {
    attrs: {
      "className": "sub-tab-wrap",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }