var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "U the STAR",
      "tabActive": "재단소개",
      "bg": "/images/sub/visual/sv1.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "재단소개",
      "tabActive": "2"
    }
  }), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold mb-28 mb-lg-48"
  }, [_vm._v("인천(청라)지사")]), _c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-8 mb-lg-14"
  }, [_vm._v("아시안 게임을 개최한 국내 3대 도시 “인천”")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("이제 인천의 아이들을 위한 체육 봉사 활동을 시작하려고 합니다.")])]), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('slide-primary', {
    attrs: {
      "slideNum": "2",
      "className": "pagination-bottom"
    }
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img1.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img2.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img3.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img5.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img6.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img7.jpg",
      "alt": ""
    }
  })])])], 1), _c('div', {
    staticClass: "mb-30 mb-lg-40"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "01"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v("스포츠 교육")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 교육부 진로체험 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 프로그램 연계 ")])])], 1), _c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "02"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v("진로체험 프로그램")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 인천지역 초,중,고 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 정규 체육수업 포함 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "03"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v("스포츠 바우처 활용")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 차상위 계층 아이들의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 공평한 체육활동 제공 ")])])], 1)], 1)], 1), _c('div', {
    staticClass: "w-100 py-14 border-top border-bottom mb-50 mb-lg-72"
  }, [_c('p', {
    staticClass: "page-text text-center color-grey"
  }, [_vm._v("* 재능 기부(체육 관련) 혹은 각종 후원 활동을 해주실 다양한 인재를 찾습니다.")])]), _c('h5', {
    staticClass: "tit--sm font-weight-bold mb-10 mb-lg-26"
  }, [_vm._v("문의하기")]), _c('div', {
    staticClass: "pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4 bg-white"
  }, [_c('div', {
    staticClass: "d-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v("문의")]), _c('a', {
    staticClass: "d-block",
    staticStyle: {
      "max-width": "calc(100% - 72px)"
    },
    attrs: {
      "href": "/inquiry"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/about/own/inquiry-button.png"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v("이메일")]), _c('a', {
    staticClass: "d-block color-grey page-text",
    attrs: {
      "href": "mailto:choiborn@uthestar.org"
    }
  }, [_vm._v(" wonjin6083@uthestar.org ")])])])], 1)], 1), _c('section', {
    staticClass: "section bg-grey"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold mb-28 mb-lg-48"
  }, [_vm._v("(경기도)하남지사")]), _c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-8 mb-lg-14"
  }, [_vm._v("새로운 스포츠 도시를 꿈꾸다. \"하남\"")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 세상의 미래가 될 우리 아이들이 꿈꾸는 스포츠 신도시 \"하남\"에서 "), _c('br'), _vm._v(" 재단법인 유더스타스포츠의 활동이 시작됩니다. ")])]), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('slide-primary', {
    attrs: {
      "slideNum": "1",
      "className": "pagination-bottom"
    }
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/about/own/s1-img1.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/about/own/s1-img2.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/about/own/s1-img3.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/about/own/s1-img4.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/about/own/s1-img5.jpg",
      "alt": ""
    }
  })])])], 1), _c('div', {
    staticClass: "mb-30 mb-lg-40"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "01"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v("스포츠 교육")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 교육부 진로체험 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 프로그램 연계 ")])])], 1), _c('v-col', {
    staticClass: "mb-10 mb-sm-0",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "02"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v("스포츠 바우처 활용")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 차상위 계층 아이들의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 공평한 체육활동 제공 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "03"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v("스포츠 재능기부")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 하남리틀야구단 출신 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 프로선수를 중심으로 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 아동청소년등 체육사각지역 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 아이들 체육재능기부 ")])])], 1)], 1)], 1), _c('div', {
    staticClass: "w-100 py-14 border-top border-bottom mb-50 mb-lg-72"
  }, [_c('p', {
    staticClass: "page-text text-center color-grey"
  }, [_vm._v("* 재능(체육관련)기부 및 각종 후원활동을 함께해줄 다양한 멘토를 찾습니다.")])]), _c('h5', {
    staticClass: "tit--sm font-weight-bold mb-10 mb-lg-26"
  }, [_vm._v("문의하기")]), _c('div', {
    staticClass: "pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4"
  }, [_c('div', {
    staticClass: "d-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v("문의")]), _c('a', {
    staticClass: "d-block",
    staticStyle: {
      "max-width": "calc(100% - 72px)"
    },
    attrs: {
      "href": "/inquiry"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/about/own/inquiry-button.png"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v("이메일")]), _c('a', {
    staticClass: "d-block color-grey page-text",
    attrs: {
      "href": "mailto:suwon@uthestar.org"
    }
  }, [_vm._v("hanam@uthestar.org")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }