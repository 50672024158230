<template>
    <client-page>

        <sub-visual
        sh="U the STAR"
        tabActive="공지사항"
        bg="/images/sub/visual/sv1.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <notification-list v-if="!$route.params._notification"></notification-list>
                <notification-view v-else></notification-view>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import NotificationList from "@/components/client/ko/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/ko/center/notifications/notification-view.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        NotificationList,
		NotificationView
    },
};
</script>

<style lang="scss" scoped>

</style>