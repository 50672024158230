<template>
    <div class="center-top">
        <slot/>
        <div class="txt-box pa-20 pa-md-30 px-lg-50 d-flex align-center">
            <div>
                <div class="d-flex">
                    <h5 class="tit--sm font-weight-bold color-point w-20px w-lg-40px mr-14 mr-lg-10">
                        01
                    </h5>
                    <p class="page-text--lg">
                        {{sc1}}
                    </p>
                </div>
                <div class="d-flex mt-8 mt-lg-22" v-if="sc2">
                    <h5 class="tit--sm font-weight-bold color-point w-20px w-lg-40px mr-14 mr-lg-10">
                        02
                    </h5>
                    <p class="page-text--lg">
                        {{sc2}}
                    </p>
                </div>
                <div class="d-flex mt-8 mt-lg-22" v-if="sc3">
                    <h5 class="tit--sm font-weight-bold color-point w-20px w-lg-40px mr-14 mr-lg-10">
                        03
                    </h5>
                    <p class="page-text--lg">
                        {{sc3}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    props : {
        sc1 : {type :String, default : ""},
        sc2 : {type :String, default : ""},
        sc3 : {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	// mounted() {
	// 	this.init();
	// },
	// methods : {
	// 	init : function() {
    //         setTimeout(() => {
    //             AOS.init({
    //                 once: false,
    //                 offset: 240,
    //                 duration: 500,
    //                 throttleDelay : 100,
    //                 easing : 'ease-in-out',
    //                 anchorPlacement : 'top-center',
    //             });
    //         }, 100);
	// 	}
	// },
}
</script>

<style scoped>
.center-top{
    position: relative;
}
.center-top .txt-box{
    width: 100%;
    background-color: var(--v-secondary-base);
    color: #fff;
}
.center-top .txt-box p{
    max-width: calc(100% - 34px);
}
@media (min-width:576px){
}
@media (min-width:768px){
    .center-top .txt-box{
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 3;
        bottom: 0;
        right: 0;
        height: 170px;
        width: 720px;
    }
    .center-top::before{
        content: "";
        display: block;
        position: absolute;
        width: 100vw;
        height: 170px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f7f8f9;
    }
}
@media (min-width:1024px){
    .center-top .txt-box{
        height: 248px;
    }
    .center-top .txt-box p{
        max-width: calc(100% - 50px);
    }
    .center-top::before{
        height: 248px;
    }
}
@media (min-width:1200px){
}
</style>