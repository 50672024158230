<template>
    <client-page>

        <sub-visual
        sh="U the STAR"
        tabActive="재단소개"
        bg="/images/sub/visual/sv1.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <dept3-tab page="재단소개" tabActive="3"></dept3-tab>

                <div class="top-image mt-lg-80">
                    <v-row class="flex-column-reverse flex-md-row">
                        <v-col cols="12" md="9" lg="7">
                            <h3 class="tit mb-26 mb-lg-44">
                                안녕하십니까? <br>
                                재단법인 유더스타스포츠 <br>
                                이사장 유인국 인사드립니다.
                            </h3>
                            <p class="page-text color-grey mb-4 mb-lg-22">
                                개인적인 포부와 희망을 품고 한 우물만 보고 사업에만 몰두하다 이 사회를 위해 <br class="d-none d-xl-block">
                                무엇인가 봉사를 하고 싶은 마음에 서울 강서구 지역내에서 체육회 이사회장, <br class="d-none d-xl-block">
                                장학회이사, 민주평화통일자문회의 협의회장까지 여러 가지 직책을 맡으면서 나름의 <br class="d-none d-xl-block">
                                소신을 가지고 최선을 다해 지역에 봉사하고 활동해 왔습니다.
                            </p>
                             <p class="page-text color-grey mb-4 mb-lg-22">
                                그 중 소싯적부터 현재까지도 즐기고 있는 여러가지 체육 활동이 저 뿐만 아니라 이 지역, <br class="d-none d-xl-block">
                                사회, 국가에 건강한 도움이 될 수 있을 것 같다는 생각으로 체육 활동 및 시설을 <br class="d-none d-xl-block">
                                공유해야겠다는 굳은 마음으로 체육시설 재단법인을 설립하게 되었습니다.
                            </p>
                            <p class="page-text color-grey mb-4 mb-lg-22">
                                우리 체육재단은 엘리트 체육인의 양성 및 선수 육성의 목적과 올바른 인성을 갖춘 <br class="d-none d-xl-block">
                                체육인으로 성장 할 수 있도록 지원하고, 인기 스포츠를 쉽게 접할 수 없었던 차상위계층을 <br class="d-none d-xl-block">
                                위해 “같이 할 수 있는 스포츠“, ”함께 할 수 있는 스포츠”를 만들어 보고자 합니다. 
                            </p>
                            <p class="page-text color-grey mb-4 mb-lg-22">
                                그런 재단이 될 수 있도록 우리 재단을 응원해주시고, 우리 재단시설을 마음껏 이용해 <br class="d-none d-xl-block">
                                주셔서 많은 사랑과 관심을 가져 주시길 바랍니다.
                            </p>
                            <p class="page-text color-grey mb-4 mb-lg-22">
                                또한, 대중에게 더 가깝게 다가가기 위해 우리 재단이 할 수 있는 것을 고민한 끝에 <br class="d-none d-xl-block">
                                [재단법인 유더스타스포츠]는 “블루스폿 필드”와 "블루팟 그라운드" 2개의 운동장을 준비 <br class="d-none d-xl-block">
                                및 개방하여 다양한 체육활동을 진행 중에 있으며, 이것을 통해 우리 재단의 목표를 실현해 <br class="d-none d-xl-block">
                                나가고 있습니다.    
                            </p>
                            <p class="page-text color-grey mb-12 mb-lg-0">
                                가정, 학교, 기업, 멤버쉽 모임까지 다양한 스포츠를 체험하고 싶은 분들을 모시고 즐겁고 <br class="d-none d-xl-block">
                                재미있는 스포츠의 세계로 여러분을 초대하고 싶습니다. 감사합니다.
                            </p>
                            <div class="d-block d-lg-none">
                                <p class="page-text color-point">
                                    재단법인 유더스타스포츠 이사장 
                                </p>
                                <h5 class="tit--sm font-weight-bold">
                                    유인국 드림
                                </h5>
                            </div>
                        </v-col>
                        <v-col cols="12" md="3" lg="5" class="d-flex justify-lg-end">
                            <div class="d-flex flex-column justify-space-between">
                                <div>
                                    <img src="/images/sub/about/greeting/s1-img.jpg" alt="" class="d-block mb-14 mb-lg-30">
                                    <img src="/images/logo-light.png" alt="" class="d-block">
                                </div>
                                <div class="d-none d-lg-block">
                                    <p class="page-text color-point mb-10">
                                        재단법인 유더스타스포츠 이사장 
                                    </p>
                                    <h5 class="tit--sm font-weight-bold">
                                        유인국 드림
                                    </h5>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
    },
};
</script>

<style lang="scss" scoped>
.top-image::before{
    background-image: url(/images/sub/about/greeting/s1-bg.jpg);
}
</style>