var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "시설 및 참여",
      "tabActive": "시설소개",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "시설소개",
      "tabActive": "4"
    }
  }), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" 대만 가오슝 국경·칭푸 ")])]), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('center-top', {
    attrs: {
      "sc1": "대만 가오슝 국경 칭푸A, 국경 칭푸B 2개면의 프로야구 정식 규격 규모의 야구장 시설을 갖춘 대만 가오슝 칭푸의 야구장 시설입니다.",
      "sc2": "400m거리의 시설로 프로/ 아마추어 야구단 전지 훈련 및 관람이 가능합니다."
    }
  }, [_c('slide-primary', {
    attrs: {
      "slideNum": "1",
      "className": "pagination-top"
    }
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/taiwan/s1-img1.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/taiwan/s1-img2.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/taiwan/s1-img3.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/taiwan/s1-img4.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/taiwan/s1-img5.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/taiwan/s1-img6.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/taiwan/s1-img7.jpg",
      "alt": ""
    }
  })])])], 1)], 1), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "01"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/taiwan/s1-icon1.png",
      "maxWidth": "44"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 야구장 독점사용! "), _c('br'), _vm._v(" 이용시간과 요일에 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 제한 받지 않음 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "02"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/taiwan/s1-icon2.png",
      "maxWidth": "38"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 대만팀과 경기! "), _c('br'), _vm._v(" 연습경기 주선 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 초청이 수월 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "03"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/taiwan/s1-icon3.png",
      "maxWidth": "45"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 다양한 부대시설! "), _c('br'), _vm._v(" 자유로운 시설보강 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 및 설계조율 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "04"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/taiwan/s1-icon4.png",
      "maxWidth": "52"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 국가지원 가능! "), _c('br'), _vm._v(" 대만 정부, 교육부 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 야구계 지원 가능 ")])], 1)], 1)], 1)], 1), _c('h5', {
    staticClass: "tit--sm font-weight-bold mb-10 mb-lg-26"
  }, [_vm._v("이용내역")]), _c('div', {
    staticClass: "pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4"
  }, [_c('div', {
    staticClass: "d-lg-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 2020 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" 키움히어로즈 스프링 캠프 ")])]), _c('div', {
    staticClass: "d-lg-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 2019 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" KT위즈 마무리 캠프 (유소년 견학 캠프 동시 진행) ")])]), _c('div', {
    staticClass: "d-lg-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 2018~2019 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" 롯데자이언츠 1군 스프링 캠프 ")])]), _c('div', {
    staticClass: "d-lg-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 2016~2019 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" 롯데자이언츠 2군 스프링 캠프 ")])]), _c('div', {
    staticClass: "d-lg-flex align-center"
  }, [_c('p', {
    staticClass: "w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 2016~2017 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" 경기고등학교 전지훈련 ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }