var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('section', {
    staticClass: "sub-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "mb-24 w-100 bg-grey pa-20 pa-md-30"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": "/images/sub/donation/donation/donation-logo.png",
      "max-width": "215"
    }
  })], 1), _c('p', {
    staticClass: "text-right page-text--sm line-height-1 color-grey mb-4 mb-lg-8"
  }, [_c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력입니다")]), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("이름 (혹은 단체 기관명) "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.demo.name,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "name", $$v);
      },
      expression: "demo.name"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("이메일 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "이메일",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email1,
      callback: function ($$v) {
        _vm.email1 = $$v;
      },
      expression: "email1"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("@")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email2,
      callback: function ($$v) {
        _vm.email2 = $$v;
      },
      expression: "email2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-select', _vm._b({
    attrs: {
      "persistent-hint": "",
      "items": _vm.prependEmails,
      "item-text": "text",
      "item-value": "value",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.email2 = _vm.email3;
      }
    },
    model: {
      value: _vm.email3,
      callback: function ($$v) {
        _vm.email3 = $$v;
      },
      expression: "email3"
    }
  }, 'v-select', _vm.$attrs, false))], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("생년월일 (혹은 사업자번호) "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.demo.birthDate,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "birthDate", $$v);
      },
      expression: "demo.birthDate"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("연락처 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.prependPhones,
      "placeholder": "연락처",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone1,
      callback: function ($$v) {
        _vm.phone1 = $$v;
      },
      expression: "phone1"
    }
  }, 'v-select', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone2,
      callback: function ($$v) {
        _vm.phone2 = $$v;
      },
      expression: "phone2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone3,
      callback: function ($$v) {
        _vm.phone3 = $$v;
      },
      expression: "phone3"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("주소 "), _c('span', {
    staticStyle: {
      "color": "#ff0000"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "10"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "우편번호",
      "readonly": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.searchAddress
    },
    model: {
      value: _vm.demo.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "postcode", $$v);
      },
      expression: "demo.postcode"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto",
      "sm": "2"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 v-btn--input",
    attrs: {
      "tile": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.searchAddress
    }
  }, [_vm._v("검색")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "기본주소",
      "readonly": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.searchAddress
    },
    model: {
      value: _vm.demo.address1,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "address1", $$v);
      },
      expression: "demo.address1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "상세주소",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.demo.address2,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "address2", $$v);
      },
      expression: "demo.address2"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("기부금 영수증 수령 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.demo.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "inquiry", $$v);
      },
      expression: "demo.inquiry"
    }
  }, [_c('v-row', {
    staticClass: "my-n2 my-lg-n6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "label": "홈텍스 (연말정산 간소화 서비스) 이용",
      "value": "홈텍스 (연말정산 간소화 서비스) 이용"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "label": "우편수령",
      "value": "우편수령"
    }
  })], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("주민등록번호 앞자리 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("(혹은 사업자등록번호) "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.demo.personalNum,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "personalNum", $$v);
      },
      expression: "demo.personalNum"
    }
  })], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("후원유형 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.demo.donationiType,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "donationiType", $$v);
      },
      expression: "demo.donationiType"
    }
  }, [_c('v-row', {
    staticClass: "my-n2 my-lg-n6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto",
      "lg": "3"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "value": "정기후원 (10만원)",
      "label": "정기후원 (10만원)"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto",
      "lg": "3"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "value": "정기후원 (5만원)",
      "label": "정기후원 (5만원)"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto",
      "lg": "3"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "value": "정기후원 (3만원)",
      "label": "정기후원 (3만원)"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto",
      "lg": "3"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "value": "정기후원 (2만원)",
      "label": "정기후원 (2만원)"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto",
      "lg": "3"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "value": "정기후원 (1만원)",
      "label": "정기후원 (1만원)"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "value": "일시후원",
      "label": "일시후원"
    }
  }), _vm.demo.donationiType == '일시후원' ? _c('v-text-field', {
    attrs: {
      "messages": ['일시후원금액은 계좌 국민은행 695001-01-330024 재단법인 유더스타스포츠로 이체를 부탁드립니다.'],
      "outlined": "",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.demo.memo,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "memo", $$v);
      },
      expression: "demo.memo"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-radio', {
    attrs: {
      "hide-details": "",
      "value": "기타",
      "label": "기타"
    }
  }), _vm.demo.donationiType == '기타' ? _c('v-text-field', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.demo.memo,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "memo", $$v);
      },
      expression: "demo.memo"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)]), _vm.demo.donationiType !== '일시후원' && _vm.demo.donationiType !== '기타' ? _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("CMS 자동이체 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("후원계좌정보 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('div', {
    staticClass: "mb-4 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "1"
    }
  }, [_c('p', {
    staticClass: "page-text font-weight-bold"
  }, [_vm._v("은행명")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "11"
    }
  }, [_c('div', {
    staticClass: "w-100 pl-lg-30"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.demo.bank,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "bank", $$v);
      },
      expression: "demo.bank"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)])], 1)], 1), _c('div', {
    staticClass: "mb-4 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "1"
    }
  }, [_c('p', {
    staticClass: "page-text font-weight-bold"
  }, [_vm._v("계좌번호")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "11"
    }
  }, [_c('div', {
    staticClass: "w-100 pl-lg-30"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.demo.accountNum,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "accountNum", $$v);
      },
      expression: "demo.accountNum"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)])], 1)], 1), _c('div', {
    staticClass: "mb-4 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "1"
    }
  }, [_c('p', {
    staticClass: "page-text font-weight-bold"
  }, [_vm._v("예금주")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "11"
    }
  }, [_c('div', {
    staticClass: "w-100 pl-lg-30"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.demo.detailAddress,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "detailAddress", $$v);
      },
      expression: "demo.detailAddress"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)])], 1)], 1), _c('div', {
    staticClass: "mb-4 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "md": "1"
    }
  }, [_c('p', {
    staticClass: "page-text font-weight-bold"
  }, [_vm._v("이체일")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "11"
    }
  }, [_c('div', {
    staticClass: "w-100 pl-lg-30"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": "5일/10일/15일/20일/25일"
    },
    model: {
      value: _vm.demo.transferDate,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "transferDate", $$v);
      },
      expression: "demo.transferDate"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)])], 1)], 1)])]) : _vm._e()]), _c('div', {
    staticClass: "d-flex flex-column align-center justify-center border border-color-light border-2 border-lg-4 pa-20 pa-md-30 mt-30 mt-lg-40"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 수집 · 이용 동의",
      "value": "개인정보 수집·이용 동의",
      "hide-details": ""
    },
    model: {
      value: _vm.agree1,
      callback: function ($$v) {
        _vm.agree1 = $$v;
      },
      expression: "agree1"
    }
  }), _c('v-btn', {
    staticClass: "ml-8 ml-lg-14",
    attrs: {
      "to": "/privacy-policy",
      "color": "primary",
      "text": ""
    }
  }, [_vm._v("전문보기")])], 1), _c('div', {
    staticClass: "d-flex align-center mt-lg-n10"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 제3자 제공 동의",
      "value": "개인정보 제3자 제공 동의",
      "hide-details": ""
    },
    model: {
      value: _vm.agree2,
      callback: function ($$v) {
        _vm.agree2 = $$v;
      },
      expression: "agree2"
    }
  }), _c('v-btn', {
    staticClass: "ml-8 ml-lg-14",
    attrs: {
      "to": "/privacy-use",
      "color": "primary",
      "text": ""
    }
  }, [_vm._v("전문보기")])], 1)]), _c('div', {
    staticClass: "d-flex flex-column align-center justify-center mt-50 mt-lg-80 text-center"
  }, [_c('p', {
    staticClass: "page-text mb-8 mb-lg-14"
  }, [_vm._v(" 신청일자 : " + _vm._s(new Date().toISOString().substring(0, 10)) + " ")]), _c('div', {
    staticClass: "d-flex flex-column flex-md-row align-center"
  }, [_c('div', {
    staticClass: "d-flex align-center mr-md-12 mr-lg-24 mb-6 mb-md-0"
  }, [_c('p', {
    staticClass: "page-text font-weight-bold mr-8 mr-lg-16"
  }, [_vm._v("서명")]), _c('v-text-field', {
    staticClass: "max-w-180px max-w-lg-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.sign,
      callback: function ($$v) {
        _vm.sign = $$v;
      },
      expression: "sign"
    }
  })], 1), _c('v-checkbox', {
    attrs: {
      "label": "확인",
      "value": "확인",
      "hide-details": ""
    },
    model: {
      value: _vm.check,
      callback: function ($$v) {
        _vm.check = $$v;
      },
      expression: "check"
    }
  })], 1)]), _c('div', {
    staticClass: "bg-grey pa-20 pa-md-30 pa-lg-50 w-100 mt-30 mt-lg-60"
  }, [_c('div', {
    staticClass: "mb-20 mb-lg-12"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align-lg": "center"
    }
  }, [_c('v-col', {
    staticClass: "w-100 w-lg-240px text-center",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 py-16 bg-white"
  }, [_c('p', {
    staticClass: "page-text font-weight-bold color-point line-height-1"
  }, [_vm._v(" 기부 및 후원금 납부계좌 ")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "d-lg-flex align-center"
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold"
  }, [_vm._v("국민은행 695001-01-330024")]), _c('p', {
    staticClass: "page-text color-grey ml-lg-10"
  }, [_vm._v("(예금주: 재단법인 유더스타스포츠)")])])])], 1)], 1), _c('div', {
    staticClass: "mb-20 mb-lg-12"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align-lg": "center"
    }
  }, [_c('v-col', {
    staticClass: "w-100 w-lg-240px text-center",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 py-16 bg-white"
  }, [_c('p', {
    staticClass: "page-text font-weight-bold color-point line-height-1"
  }, [_vm._v(" 문의 전화 ")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold"
  }, [_vm._v("02-3662-3210")])])], 1)], 1), _c('div', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align-lg": "center"
    }
  }, [_c('v-col', {
    staticClass: "w-100 w-lg-240px text-center",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 py-16 bg-white"
  }, [_c('p', {
    staticClass: "page-text font-weight-bold color-point line-height-1"
  }, [_vm._v(" 팩스 번호 ")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold"
  }, [_vm._v("02-3664-2401")])])], 1)], 1)]), _c('div', {
    staticClass: "mt-30 mt-lg-60 text-center"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-200px",
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("신청하기")])], 1)])], 1), _c('daum-postcode', {
    ref: "daum-postcode",
    on: {
      "change": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.demo.postcode = postcode;
        _vm.demo.address1 = address;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }