var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center-top"
  }, [_vm._t("default"), _c('div', {
    staticClass: "txt-box pa-20 pa-md-30 px-lg-50 d-flex align-center"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point w-20px w-lg-40px mr-14 mr-lg-10"
  }, [_vm._v(" 01 ")]), _c('p', {
    staticClass: "page-text--lg"
  }, [_vm._v(" " + _vm._s(_vm.sc1) + " ")])]), _vm.sc2 ? _c('div', {
    staticClass: "d-flex mt-8 mt-lg-22"
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point w-20px w-lg-40px mr-14 mr-lg-10"
  }, [_vm._v(" 02 ")]), _c('p', {
    staticClass: "page-text--lg"
  }, [_vm._v(" " + _vm._s(_vm.sc2) + " ")])]) : _vm._e(), _vm.sc3 ? _c('div', {
    staticClass: "d-flex mt-8 mt-lg-22"
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point w-20px w-lg-40px mr-14 mr-lg-10"
  }, [_vm._v(" 03 ")]), _c('p', {
    staticClass: "page-text--lg"
  }, [_vm._v(" " + _vm._s(_vm.sc3) + " ")])]) : _vm._e()])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }