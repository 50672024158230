var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dept3-tab mb-50 mb-lg-72"
  }, [_vm.page == '재단소개' ? _c('ul', {
    staticClass: "tab tab--block"
  }, [_c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '1'
    }
  }, [_vm._m(0)]), _c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '2'
    }
  }, [_vm._m(1)]), _c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '3'
    }
  }, [_vm._m(2)])]) : _vm._e(), _vm.page == '시설소개' ? _c('ul', {
    staticClass: "tab tab--block"
  }, [_c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '1'
    }
  }, [_vm._m(3)]), _c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '2'
    }
  }, [_vm._m(4)]), _c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '3'
    }
  }, [_vm._m(5)]), _c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '4'
    }
  }, [_vm._m(6)])]) : _vm._e(), _vm.page == '코칭스탭' ? _c('ul', {
    staticClass: "tab tab--block"
  }, [_c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '1'
    }
  }, [_vm._m(7)]), _c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '2'
    }
  }, [_vm._m(8)])]) : _vm._e(), _vm.page == '프로그램' ? _c('ul', {
    staticClass: "tab tab--block"
  }, [_c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '1'
    }
  }, [_vm._m(9)]), _c('li', {
    staticClass: "tab__li",
    class: {
      active: _vm.tabActive == '2'
    }
  }, [_vm._m(10)])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/about/business"
    }
  }, [_c('span', [_vm._v("재단사업소개")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/about/own"
    }
  }, [_c('span', [_vm._v("지사소개")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/about/greeting"
    }
  }, [_c('span', [_vm._v("이사장 인사말")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/square"
    }
  }, [_c('span', [_vm._v("스퀘어")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/field"
    }
  }, [_c('span', [_vm._v("필드")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/ground"
    }
  }, [_c('span', [_vm._v("그라운드")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/taiwan"
    }
  }, [_c('span', [_vm._v("대만 국경·칭푸")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/staff1"
    }
  }, [_c('span', [_vm._v("야구")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/staff5"
    }
  }, [_c('span', [_vm._v("축구")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/seoul"
    }
  }, [_c('span', [_vm._v("서울본점")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "/center/cheongna"
    }
  }, [_c('span', [_vm._v("청라지사")])]);

}]

export { render, staticRenderFns }