import { render, staticRenderFns } from "./ProgramPage.vue?vue&type=template&id=3668544e&scoped=true"
import script from "./ProgramPage.vue?vue&type=script&lang=js"
export * from "./ProgramPage.vue?vue&type=script&lang=js"
import style0 from "./ProgramPage.vue?vue&type=style&index=0&id=3668544e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3668544e",
  null
  
)

export default component.exports