var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: 'rounded bg-grey w-80px w-lg-100px h-80px h-lg-100px d-flex align-center justify-center ' + _vm.className
  }, [_c('v-img', {
    attrs: {
      "src": _vm.icon,
      "max-width": _vm.maxWidth
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }