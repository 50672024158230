var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.baseball ? _c('v-tabs', {
    staticClass: "sub-staff-tab",
    attrs: {
      "grow": "",
      "hide-slider": ""
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/center/staff1"
    }
  }, [_vm._v("현남수 총감독")]), _c('v-tab', {
    attrs: {
      "to": "/center/staff2"
    }
  }, [_vm._v("박상혁 감독")]), _c('v-tab', {
    attrs: {
      "to": "/center/staff3"
    }
  }, [_vm._v("김원진 수석 코치")]), _c('v-tab', {
    attrs: {
      "to": "/center/staff4"
    }
  }, [_vm._v("홍승우 타격 코치")])], 1) : _c('v-tabs', {
    staticClass: "sub-staff-tab",
    attrs: {
      "grow": "",
      "hide-slider": ""
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/center/staff5"
    }
  }, [_vm._v("이정훈 축구 코치")]), _c('v-tab', {
    attrs: {
      "to": "/center/staff6"
    }
  }, [_vm._v("김성민 축구 코치")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }