<template>
    <div class="dept3-tab mb-50 mb-lg-72">
        <ul class="tab tab--block" v-if="page == '재단소개'">
            <li class="tab__li" :class="{ active: tabActive == '1' }">
                <a href="/about/business" class="tab__btn"><span>재단사업소개</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '2' }">
                <a href="/about/own" class="tab__btn"><span>지사소개</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '3' }">
                <a href="/about/greeting" class="tab__btn"><span>이사장 인사말</span></a>
            </li>
        </ul>

        <ul class="tab tab--block" v-if="page == '시설소개'">
            <li class="tab__li" :class="{ active: tabActive == '1' }">
                <a href="/center/square" class="tab__btn"><span>스퀘어</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '2' }">
                <a href="/center/field" class="tab__btn"><span>필드</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '3' }">
                <a href="/center/ground" class="tab__btn"><span>그라운드</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '4' }">
                <a href="/center/taiwan" class="tab__btn"><span>대만 국경·칭푸</span></a>
            </li>
        </ul>

        <ul class="tab tab--block" v-if="page == '코칭스탭'">
            <li class="tab__li" :class="{ active: tabActive == '1' }">
                <a href="/center/staff1" class="tab__btn"><span>야구</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '2' }">
                <a href="/center/staff5" class="tab__btn"><span>축구</span></a>
            </li>
        </ul>

        <!-- <ul class="tab tab--block" v-if="page == '야구'">
            <li class="tab__li w-50 w-md-auto" :class="{ active: tabActive == '1' }">
                <a href="/center/staff1" class="tab__btn"><span>현남수</span></a>
            </li>
            <li class="tab__li w-50 w-md-auto" :class="{ active: tabActive == '2' }">
                <a href="/center/staff2" class="tab__btn"><span>박상혁</span></a>
            </li>
            <li class="tab__li w-50 w-md-auto" :class="{ active: tabActive == '3' }">
                <a href="/center/staff3" class="tab__btn"><span>김원진</span></a>
            </li>
            <li class="tab__li w-50 w-md-auto" :class="{ active: tabActive == '4' }">
                <a href="/center/staff4" class="tab__btn"><span>홍승우</span></a>
            </li>
        </ul> -->

        <!-- <ul class="tab tab--block" v-if="page == '축구'">
            <li class="tab__li" :class="{ active: tabActive == '1' }">
                <a href="/center/staff5" class="tab__btn"><span>이정훈</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '2' }">
                <a href="/center/staff6" class="tab__btn"><span>김성민</span></a>
            </li>
        </ul> -->

        <ul class="tab tab--block" v-if="page == '프로그램'">
            <li class="tab__li" :class="{ active: tabActive == '1' }">
                <a href="/center/seoul" class="tab__btn"><span>서울본점</span></a>
            </li>
            <li class="tab__li" :class="{ active: tabActive == '2' }">
                <a href="/center/cheongna" class="tab__btn"><span>청라지사</span></a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        page: { type: String, default: "" },
        tabActive: { type: String, default: "" },
    },
    components: {},
    data: () => {
        return {};
    },
    created() {},
    // mounted() {
    // 	this.init();
    // },
    // methods : {
    // 	init : function() {
    //         setTimeout(() => {
    //             AOS.init({
    //                 once: false,
    //                 offset: 240,
    //                 duration: 500,
    //                 throttleDelay : 100,
    //                 easing : 'ease-in-out',
    //                 anchorPlacement : 'top-center',
    //             });
    //         }, 100);
    // 	}
    // },
};
</script>

<style scoped>
.dept3-tab .tab--block .tab__li {
    position: relative;
    padding-bottom: 8px;
}

.dept3-tab .tab--block .tab__li::after {
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 8px;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    background-image: url(/images/common/tab-point.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    visibility: hidden;
}

.dept3-tab .tab--block .tab__li.active::after {
    opacity: 1;
    visibility: visible;
    bottom: 0;
}

.dept3-tab .tab--block .tab__btn {
    background-color: #f7f8f9;
    color: #666;
    font-weight: 700;
}

.dept3-tab .tab--block .tab__li.active .tab__btn {
    background-color: var(--v-primary-base);
    color: #fff;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .dept3-tab .tab--block .tab__li {
        padding-bottom: 10px;
    }
    .dept3-tab .tab--block .tab__li::after {
        width: 16px;
        height: 10px;
    }
    .dept3-tab .tab--block .tab__li:hover::after {
        opacity: 1;
        visibility: visible;
        bottom: 0;
        transition: all ease-out 0.3s 0.15s;
    }
    .dept3-tab .tab--block .tab__li:hover .tab__btn {
        background-color: var(--v-primary-base);
        color: #fff;
    }
}
@media (min-width: 1200px) {
}
</style>
