<template>
    <div class="form-wrap">
        <section class="sub-section">
            <v-container>
                <p class="text-right page-text--sm line-height-1 color-grey mb-4 mb-lg-8"><span style="color:var(--v-primary-base)">*</span> 필수입력입니다</p>
                <div class="table-style table-style--default">
                    <div class="table-style__row">
                        <div class="table-style__th">이름 (혹은 단체 기관명) <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-text-field v-model="demo.name" v-bind="$attrs" outlined hide-details clearable />
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">이메일 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-row align="center" class="row--x-small">
                                <v-col>
                                    <v-text-field v-model="email1" v-bind="$attrs" outlined hide-details @input="() => emailJoin()"/>
                                </v-col>
                                <v-col cols="auto">@</v-col>
                                <v-col>
                                    <v-text-field v-model="email2" v-bind="$attrs" outlined hide-details @input="() => emailJoin()"/>
                                </v-col>
                                <v-col cols="auto">-</v-col>
                                <v-col>
                                    <v-select v-model="email3" v-bind="$attrs" persistent-hint :items="prependEmails" item-text="text" item-value="value" outlined hide-details @input="email2 = email3"/>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">연락처 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-row align="center" class="row--x-small">
                                <v-col>
                                    <v-select v-model="phone1" :items="prependPhones" v-bind="$attrs" placeholder="연락처" outlined hide-details @input="() => phoneNumberJoin()"/>
                                </v-col>
                                <v-col cols="auto">-</v-col>
                                <v-col>
                                    <v-text-field v-model="phone2" v-bind="$attrs" maxlength="4" outlined hide-details @input="() => phoneNumberJoin()"/>
                                </v-col>
                                <v-col cols="auto">-</v-col>
                                <v-col>
                                    <v-text-field v-model="phone3" v-bind="$attrs" maxlength="4" outlined hide-details @input="() => phoneNumberJoin()"/>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">문의사항 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-row align="center" class="my-n2 my-lg-n6">
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <v-checkbox hide-details v-model="demo.inquiry" value="취미,선수반 문의" :label="`취미,선수반 문의`"></v-checkbox>
                                </v-col>
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <v-checkbox hide-details v-model="demo.inquiry" value="진로체험 수업 문의" :label="`진로체험 수업 문의`"></v-checkbox>
                                </v-col>
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <v-checkbox hide-details v-model="demo.inquiry" value="대관 및 시설 문의" :label="`대관 및 시설 문의`"></v-checkbox>
                                </v-col>
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <v-checkbox hide-details v-model="demo.inquiry" value="레슨 문의" :label="`레슨 문의`"></v-checkbox>
                                </v-col>
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <v-checkbox hide-details v-model="demo.inquiry" value="대만 문의" :label="`대만 문의`"></v-checkbox>
                                </v-col>
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <v-checkbox hide-details v-model="demo.inquiry" value="기타 문의" :label="`기타 문의`"></v-checkbox>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">내용</div>
                        <div class="table-style__td">
                            <v-textarea rows="11" v-model="demo.content" outlined persistent-placeholder hide-details />
                        </div>
                    </div>
                </div>

                <div class="d-flex align-center justify-center border border-color-light border-2 border-lg-4 pa-20 pa-md-30 mt-30 mt-lg-40">
                    <v-checkbox v-model="agree" label="개인정보 수집·이용 동의" value="개인정보 수집·이용 동의" hide-details></v-checkbox>
                    <v-btn to="/privacy-policy" color="primary" text class="ml-8 ml-lg-14">전문보기</v-btn>
                </div>
                <div class="mt-50 mt-lg-80 text-center d-flex justify-center">
                    <v-btn x-large color="primary" @click="submit" class="min-w-140px min-w-lg-200px">문의하기</v-btn>
                </div>
            </v-container>
        </section>

    </div>
</template>
<script>

import api from "@/api";

export default {
    components: {
    },
    data(){
        return {

            demo:{
                code: "inquiry",
                name: "",
                phone: "",
                email: "",
                companyName: "",
                os: "",
                server: "",
                need: "",
                content: "",
                inquiry: [],
            },

            phone1: "010",
            phone2: "",
            phone3: "",

            email1: "",
            email2: "",
            email3: "",

            agree: false,

            prependPhones: [
                { text: "010", value: "010" },
                { text: "011", value: "011" },
                { text: "016", value: "016" },
                { text: "017", value: "017" },
                { text: "019", value: "019" }
            ],
            prependEmails: [
                { text: ":: 직접입력 ::", value: "" },
                { text: "naver.com", value: "naver.com" },
                { text: "gmail.com", value: "gmail.com" },
                { text: "daum.net", value: "daum.net" },
                { text: "hanmail.net", value: "hanmail.net" },
            ],
            settings: "",

            filter: {
                code: "ko"
            }
        }
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            const { settings } = await api.v1.boards.settings.get({
                headers: this.filter
            })
            this.settings = settings[0]
        },
        validate(){
            try{
                let demo = this.demo
                if(!demo.name) throw new Error('이름 (혹은 단체 기관명)을 입력해주세요')
                if(!demo.email || !this.email1 || !this.email2) throw new Error('이메일을 입력해주세요')
                if(!demo.phone || !this.phone1 || !this.phone2 || !this.phone3) throw new Error('연락처를 입력해주세요')
                if(demo.inquiry.length == 0) throw new Error('문의사항을 체크해주세요')
                if(!this.agree) throw new Error('개인정보 수집·이용에 동의해주세요')
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async submit(){
            if(this.validate()){
                let demo = this.demo
                let { success } = await api.v1.message.post({ demo })
                await api.v1.forms.post({ demo })
                if(success){
                    alert('문의 신청이 완료됐습니다.')
                    this.$router.push('/')
                }
            }
        },
        phoneNumberJoin(){
            this.demo.phone = `${this.phone1}-${this.phone2}-${this.phone3}`
        },
        emailJoin(){
            this.demo.email = `${this.email1}@${this.email2}`
        },
    },
    watch:{
        email3(){
            this.emailJoin()
        }
    }
}
</script>

<style scoped>
</style>