<template>
    <div :class="className + ' fade-slide fade-slide' + slideNum">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <slot/>
            </div>
        </div>
        <div class="pagination"></div>
    </div>
</template>

<script>

import Swiper from "swiper/bundle";

export default{
    props : {
        slideNum : {type :String, default : ""},
        className : {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                this.swiperA = new Swiper(".fade-slide1 .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: '.fade-slide1 .pagination',
                        clickable: true,
                    },
                });
                this.swiperB = new Swiper(".fade-slide2 .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: '.fade-slide2 .pagination',
                        clickable: true,
                    },
                });
            });
        },
    }
}
</script>

<style scoped>
    .fade-slide{
        position: relative;
    }
    .pagination{
        display: flex;
    }
    .pagination-bottom.fade-slide .pagination{
        position: absolute;
        z-index: 2;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
    }
    .pagination-top.fade-slide .pagination{
        position: absolute;
        z-index: 2;
        top: 16px;
        right: 16px;
    }
    .pagination-under.fade-slide .pagination{
        justify-content: center;
        margin-top: 16px;
    }

    @media (min-width:576px){
    }
    @media (min-width:768px){
    }
    @media (min-width:1024px){
        .pagination-bottom.fade-slide .pagination{
            bottom: 32px;
        }
        .pagination-top.fade-slide .pagination{
            top: 32px;
            right: 32px;
        }
        .pagination-under.fade-slide .pagination{
            margin-top: 30px;
        }
    }
    @media (min-width:1200px){
    }
</style>