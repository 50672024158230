<template>
    <div class="num-item w-100 h-100 pt-20 pt-lg-30">
        <div class="num w-40px w-lg-60px h-40px h-lg-60px bg-point rounded text-center d-flex align-center justify-center">
            <h5 class="tit--sm color-white text-center font-weight-bold">{{num}}</h5>
        </div>
        <div class="border bg-white border-lg-2 border-color-point w-100 h-100 d-flex flex-column align-center px-20 px-md-26 pt-40 pt-md-50 pt-lg-64 pb-20 pb-md-30 pb-lg-44 text-center">
            <slot/>
        </div>
    </div>
</template>

<script>

export default{
    props : {
        num : {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	// mounted() {
	// 	this.init();
	// },
	// methods : {
	// 	init : function() {
    //         setTimeout(() => {
    //             AOS.init({
    //                 once: false,
    //                 offset: 240,
    //                 duration: 500,
    //                 throttleDelay : 100,
    //                 easing : 'ease-in-out',
    //                 anchorPlacement : 'top-center',
    //             });
    //         }, 100);
	// 	}
	// },
}
</script>

<style scoped>
    .num-item{
        position: relative;
    }
    .num{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }
</style>