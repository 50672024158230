<template>
    <div class="board-container">
    
        <div class="mb-50 mb-lg-72">
            <v-tabs>
                <v-tab @click="filter.category = ''">전체보기</v-tab>
                <v-tab @click="filter.category = '행사 사진'">행사사진</v-tab>
                <v-tab @click="filter.category = '진로 체험'">진로체험</v-tab>
                <v-tab @click="filter.category = '대회 훈련'">대회훈련</v-tab>
            </v-tabs>
        </div>

        <div class="mb-12 mb-lg-24">
            <v-row class="row--x-small">
                <v-spacer></v-spacer>
                <v-col cols="12" md="auto">
                    <v-select class="w-100 w-md-120px" placeholder="선택" v-model="filter.searchKey" :items="filterItems" persistent-placeholder dense outlined hide-details item-text="text" item-valuie="value"></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field class="w-100 w-md-300px" v-model="filter.searchValue" :disabled="filter.searchKey == null" dense outlined hide-details placeholder="검색어를 입력하세요."></v-text-field>
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="v-btn--input w-100 min-w-md-100px" color="secondary" @click="search" dense>검색</v-btn>
                </v-col>
            </v-row>
        </div>

        <div class="board-list-gallery pt-20 pt-lg-40">

            <v-row class="my-n10 my-lg-n24">
                <v-col cols="6" md="4" lg="3" v-for="board in boards" :key="board._id" class="py-10 py-lg-24">
                    <div class="w-100" @click="$router.push(`${$route.path}/${board._id}`)" style="cursor: pointer;">
                        <div class="thum-wrap">
                            <div class="thum" :style="`background-image: url(${board.thumb})`"></div>
                        </div>
                        <h6 class="page-text--lg font-weight-bold board-list-gallery__tit mt-8 mt-lg-20">
                            {{ board.subject }}
                        </h6>
                    </div>
                </v-col>
            </v-row>

        </div>
        <div class="v-pagination-wrap">     
            <v-pagination v-model="page" :length="pageCount" @input="search()"/>
        </div>
    </div>
</template>

<script>
import api from "@/api"
export default {

    data() {
        return {
            filter: {
                searchKey: this.$route.query.searchKey || "제목+내용",
                searchValue: this.$route.query.searchValue || "",
                code: "thumbnail",
                category: ""
            },
            filterItems: [ "제목+내용", "제목", "내용" ],

            boards: [],

            page: 1,
            totalCount: 0,
            limit: 12,
            pageCount: 0,
        }
    },
    mounted() {
        this.init()
        console.log(this.pageCount);
    },
    computed: {
        skip() {
            return (this.page - 1) * this.limit
        },
    },
    methods: {
        async init() {
            let { boards, summary } = await api.v1.boards.gets({
                headers: { skip: this.skip, limit: this.limit },
                params: { ...this.filter }
            })
            this.totalCount = summary.totalCount
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);

        },
        async search() {
            let { boards, summary }  = await api.v1.boards.gets({ headers: { skip: this.skip, limit: this.limit }, params: {...this.filter} })
            this.totalCount = summary.totalCount
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            console.log(this.skip);
            console.log(this.page);
        },
    },
    watch: {
        async "filter.category"() {
            this.page = 1
            this.init();

        },
        async page() {
            await this.init()
        },
    }
}
</script>

<style lang="scss" scoped>
.board-list-gallery {
    width: 100%;
    border-top:2px solid var(--v-primary-base);
}

.thum-wrap{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc((154 / 245) * 100%);
}
.thum{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.board-list-gallery__tit{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>