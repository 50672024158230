<template>
    <div class="board-container">

        <div class="mb-12 mb-lg-24">
            <v-row class="row--x-small">
                <v-spacer></v-spacer>
                <v-col cols="12" md="auto">
                    <v-select class="w-100 w-md-120px" placeholder="선택" v-model="filter.searchKey" :items="filterItems" persistent-placeholder dense outlined hide-details item-text="text" item-valuie="value"></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field class="w-100 w-md-300px" v-model="filter.searchValue" :disabled="filter.searchKey == null" dense outlined hide-details placeholder="검색어를 입력하세요."></v-text-field>
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="v-btn--input w-100 min-w-md-100px" color="secondary" @click="search" dense>검색</v-btn>
                </v-col>
            </v-row>
        </div>
        
        <table class="board-list">
    		<thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:9%;" class="d-none d-lg-table-cell">{{$t('common.number')}}</th>
                    <th style="width:54%;">{{$t('common.제목')}}</th>
                    <th style="width:14.5%;">{{$t('common.작성자')}}</th>
                    <th style="width:14.5%;">{{$t('common.작성일')}}</th>
                    <th style="width:8%;">{{$t('common.조회수')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notification, index) in fixed" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip board-list__tit">
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        <div class="ellip">
                            <span>관리자</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.viewCount }}
                    </td>
                </tr>

                <tr v-for="(notification, index) in notifications" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <span>{{ (page-1)*limit + index + 1 }}</span>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        <div class="ellip">
                            <span>관리자</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ notification.viewCount }}
                    </td>
                </tr>
    		</tbody>
    	</table>

        <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
    </div>
</template>

<script>
import api from "@/api";
import PaginationComponent from "@/components/client/ko/control/pagination-component.vue";
import { mdiAlertCircleOutline } from '@mdi/js';
export default {
    components: {
        PaginationComponent
    },
    props: {
        value: { type: String, default: null }
    },
    data() {
        return {
            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || "제목+내용",
                searchValue: this.$route.query.searchValue || "",
                isNotice: false
            },

            filterItems: [ "제목+내용", "제목", "내용" ],

            mdiAlertCircleOutline,

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            fixed: [],
            notifications: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init() {
            await this.search();
            console.log(this.notifications, "dd");
		},
        async search() {
            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0
                },
                params: { isNotice: true }
            });

            this.fixed = fixed;

            var { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.notifications = notifications;
        },
    }
}
</script>