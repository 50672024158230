<template>
    <div class="form-wrap">
        <section class="sub-section">
            <v-container>
                <div class="mb-24 w-100 bg-grey pa-20 pa-md-30">
                    <v-img src="/images/sub/donation/donation/donation-logo.png" max-width="215" class="mx-auto"></v-img>
                </div>

                <p class="text-right page-text--sm line-height-1 color-grey mb-4 mb-lg-8"><span style="color:var(--v-primary-base)">*</span> 필수입력입니다</p>
                <div class="table-style table-style--default">
                    <div class="table-style__row">
                        <div class="table-style__th">이름 (혹은 단체 기관명) <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-text-field v-model="demo.name" v-bind="$attrs" outlined hide-details clearable />
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">이메일 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-row align="center" class="row--x-small">
                                <v-col>
                                    <v-text-field v-model="email1" v-bind="$attrs" placeholder="이메일" outlined hide-details @input="() => emailJoin()"/>
                                </v-col>
                                <v-col cols="auto">@</v-col>
                                <v-col>
                                    <v-text-field v-model="email2" v-bind="$attrs" outlined hide-details @input="() => emailJoin()"/>
                                </v-col>
                                <v-col cols="auto">-</v-col>
                                <v-col>
                                    <v-select v-model="email3" v-bind="$attrs" persistent-hint :items="prependEmails" item-text="text" item-value="value" outlined hide-details @input="email2 = email3"/>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">생년월일 (혹은 사업자번호) <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-text-field v-model="demo.birthDate" v-bind="$attrs" outlined hide-details clearable />
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">연락처 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-row align="center" class="row--x-small">
                                <v-col>
                                    <v-select v-model="phone1" :items="prependPhones" v-bind="$attrs" placeholder="연락처" outlined hide-details @input="() => phoneNumberJoin()"/>
                                </v-col>
                                <v-col cols="auto">-</v-col>
                                <v-col>
                                    <v-text-field v-model="phone2" v-bind="$attrs" maxlength="4" outlined hide-details @input="() => phoneNumberJoin()"/>
                                </v-col>
                                <v-col cols="auto">-</v-col>
                                <v-col>
                                    <v-text-field v-model="phone3" v-bind="$attrs" maxlength="4" outlined hide-details @input="() => phoneNumberJoin()"/>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">주소 <span style="color:#ff0000;">*</span></div>
                        <div class="table-style__td">
                            <v-row class="row--x-small" align="center">
                                <v-col sm="10">
                                    <v-text-field v-model="demo.postcode" @click="searchAddress" type="text" placeholder="우편번호" readonly outlined hide-details class="w-100"></v-text-field>
                                </v-col>
                                <v-col cols="auto" sm="2">
                                    <v-btn @click="searchAddress" tile color="secondary" class="w-100 v-btn--input">검색</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="demo.address1" @click="searchAddress" type="text" placeholder="기본주소" readonly outlined hide-details class="w-100"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="demo.address2" type="text" placeholder="상세주소" outlined hide-details class="w-100"></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">기부금 영수증 수령 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td ">
                            <v-radio-group hide-details v-model="demo.inquiry">
                                <v-row align="center" class="my-n2 my-lg-n6">
                                    <v-col cols="auto" class="py-2 py-lg-6">
                                        <v-radio hide-details label="홈텍스 (연말정산 간소화 서비스) 이용" value="홈텍스 (연말정산 간소화 서비스) 이용"></v-radio>
                                    </v-col>
                                    <v-col cols="auto" class="py-2 py-lg-6">
                                        <v-radio hide-details label="우편수령" value="우편수령"></v-radio>
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                            <!-- <v-row align="center" class="my-n2 my-lg-n6">
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <input type="radio" hide-details v-model="demo.inquiry" value="홈텍스 (연말정산 간소화 서비스) 이용">
                                    <label>홈텍스 (연말정산 간소화 서비스) 이용</label>
                                </v-col>
                                <v-col cols="auto" class="py-2 py-lg-6">
                                    <input type="radio" hide-details v-model="demo.inquiry" value="우편수령">
                                    <label>우편수령</label>
                                </v-col>
                            </v-row> -->
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">주민등록번호 앞자리 <br class="d-none d-lg-block">(혹은 사업자등록번호) <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-text-field v-model="demo.personalNum" outlined persistent-placeholder hide-details />
                        </div>
                    </div>
                    <div class="table-style__row">
                        <div class="table-style__th">후원유형 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <v-radio-group hide-details v-model="demo.donationiType">
                                <v-row align="center" class="my-n2 my-lg-n6">
                                    <v-col cols="auto" lg="3" class="py-2 py-lg-6">
                                        <v-radio hide-details value="정기후원 (10만원)" label="정기후원 (10만원)"></v-radio>
                                    </v-col>
                                    <v-col cols="auto" lg="3" class="py-2 py-lg-6">
                                        <v-radio hide-details value="정기후원 (5만원)" label="정기후원 (5만원)"></v-radio>
                                    </v-col>
                                    <v-col cols="auto" lg="3" class="py-2 py-lg-6">
                                        <v-radio hide-details value="정기후원 (3만원)" label="정기후원 (3만원)"></v-radio>
                                    </v-col>
                                    <v-col cols="auto" lg="3" class="py-2 py-lg-6">
                                        <v-radio hide-details value="정기후원 (2만원)" label="정기후원 (2만원)"></v-radio>
                                    </v-col>
                                    <v-col cols="auto" lg="3" class="py-2 py-lg-6">
                                        <v-radio hide-details value="정기후원 (1만원)" label="정기후원 (1만원)"></v-radio>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-radio hide-details value="일시후원" label="일시후원"></v-radio>
                                        <v-text-field v-model="demo.memo" v-if="demo.donationiType == '일시후원'" :messages="['일시후원금액은 계좌 국민은행 695001-01-330024 재단법인 유더스타스포츠로 이체를 부탁드립니다.']" outlined persistent-placeholder />
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-radio hide-details value="기타" label="기타"></v-radio>
                                        <v-text-field v-model="demo.memo" v-if="demo.donationiType == '기타'" outlined persistent-placeholder hide-details />
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                        </div>
                    </div>
                    <div v-if="demo.donationiType !== '일시후원' && demo.donationiType !== '기타'" class="table-style__row">
                        <div class="table-style__th">CMS 자동이체 <br class="d-none d-lg-block">후원계좌정보 <span style="color:var(--v-primary-base)">*</span></div>
                        <div class="table-style__td">
                            <div class="mb-4 mb-lg-10">
                                <v-row class="row--x-small">
                                    <v-col cols="12" md="1" class="d-flex align-center">
                                        <p class="page-text font-weight-bold">은행명</p>
                                    </v-col>
                                    <v-col cols="12" md="11">
                                        <div class="w-100 pl-lg-30">
                                            <v-text-field v-model="demo.bank" v-bind="$attrs" outlined hide-details clearable />
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mb-4 mb-lg-10">
                                <v-row class="row--x-small">
                                    <v-col cols="12" md="1" class="d-flex align-center">
                                        <p class="page-text font-weight-bold">계좌번호</p>
                                    </v-col>
                                    <v-col cols="12" md="11">
                                        <div class="w-100 pl-lg-30">
                                            <v-text-field v-model="demo.accountNum" v-bind="$attrs" outlined hide-details clearable />
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mb-4 mb-lg-10">
                                <v-row class="row--x-small">
                                    <v-col cols="12" md="1" class="d-flex align-center">
                                        <p class="page-text font-weight-bold">예금주</p>
                                    </v-col>
                                    <v-col cols="12" md="11">
                                        <div class="w-100 pl-lg-30">
                                            <v-text-field v-model="demo.detailAddress" v-bind="$attrs" outlined hide-details clearable />
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mb-4 mb-lg-10">
                                <v-row class="row--x-small">
                                    <v-col cols="12" md="1" class="d-flex align-center">
                                        <p class="page-text font-weight-bold">이체일</p>
                                    </v-col>
                                    <v-col cols="12" md="11">
                                        <div class="w-100 pl-lg-30">
                                            <v-text-field v-model="demo.transferDate" v-bind="$attrs" outlined hide-details clearable  placeholder="5일/10일/15일/20일/25일"/>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column align-center justify-center border border-color-light border-2 border-lg-4 pa-20 pa-md-30 mt-30 mt-lg-40">

                    <div class="d-flex align-center">
                        <v-checkbox v-model="agree1" label="개인정보 수집 · 이용 동의" value="개인정보 수집·이용 동의" hide-details></v-checkbox>
                        <v-btn to="/privacy-policy" color="primary" text class="ml-8 ml-lg-14">전문보기</v-btn>
                    </div>
                    <div class="d-flex align-center mt-lg-n10">
                        <v-checkbox v-model="agree2" label="개인정보 제3자 제공 동의" value="개인정보 제3자 제공 동의" hide-details></v-checkbox>
                        <v-btn to="/privacy-use" color="primary" text class="ml-8 ml-lg-14">전문보기</v-btn>
                    </div>

                </div>

                <div class="d-flex flex-column align-center justify-center mt-50 mt-lg-80 text-center">

                    <p class="page-text mb-8 mb-lg-14">
                        신청일자 : {{ new Date().toISOString().substring(0,10) }}
                    </p>

                    <div class="d-flex flex-column flex-md-row align-center">
                        <div class="d-flex align-center mr-md-12 mr-lg-24 mb-6 mb-md-0">
                            <p class="page-text font-weight-bold mr-8 mr-lg-16">서명</p>
                            <v-text-field v-model="sign" outlined hide-details clearable class="max-w-180px max-w-lg-240px" />
                        </div>
                        <v-checkbox v-model="check" label="확인" value="확인" hide-details></v-checkbox>
                    </div>

                </div>

                <div class="bg-grey pa-20 pa-md-30 pa-lg-50 w-100 mt-30 mt-lg-60">

                    <div class="mb-20 mb-lg-12">
                        <v-row class="row--small" align-lg="center">
                            <v-col cols="12" lg="auto" class="w-100 w-lg-240px text-center">
                                <div class="w-100 py-16 bg-white">
                                    <p class="page-text font-weight-bold color-point line-height-1">
                                        기부 및 후원금 납부계좌
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="auto">
                                <div class="d-lg-flex align-center">
                                    <h5 class="tit--sm font-weight-bold">국민은행 695001-01-330024</h5>
                                    <p class="page-text color-grey ml-lg-10">(예금주: 재단법인 유더스타스포츠)</p>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="mb-20 mb-lg-12">
                        <v-row class="row--small" align-lg="center">
                            <v-col cols="12" lg="auto" class="w-100 w-lg-240px text-center">
                                <div class="w-100 py-16 bg-white">
                                    <p class="page-text font-weight-bold color-point line-height-1">
                                        문의 전화 
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="auto">
                                <h5 class="tit--sm font-weight-bold">02-3662-3210</h5>
                            </v-col>
                        </v-row>
                    </div>

                    <div>
                        <v-row class="row--small" align-lg="center">
                            <v-col cols="12" lg="auto" class="w-100 w-lg-240px text-center">
                                <div class="w-100 py-16 bg-white">
                                    <p class="page-text font-weight-bold color-point line-height-1">
                                        팩스 번호 
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="auto">
                                <h5 class="tit--sm font-weight-bold">02-3664-2401</h5>
                            </v-col>
                        </v-row>
                    </div>

                </div>

                <div class="mt-30 mt-lg-60 text-center">
                    <v-btn x-large color="primary" @click="submit" class="min-w-140px min-w-lg-200px">신청하기</v-btn>
                </div>
            </v-container>
        </section>

        <daum-postcode ref="daum-postcode" @change="({ postcode, address }) => { demo.postcode = postcode; demo.address1 = address; }"></daum-postcode>

    </div>
</template>
<script>

import api from "@/api";

import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";


export default {
    components: {
        DaumPostcode
    },
    data(){
        return {

            demo:{
                code: "donation",
                name: "",
                phone: "",
                email: "",
                companyName: "",
                os: "",
                server: "",
                need: "",
                content: "",
                inquiry: "",
                postcode: "",
                address: "",

                birthDate: "",
                personalNum: "",
                donationiType: "",
                bank: "",
                accountNum: "",
                detailAddress: "",
                transferDate: "",

                memo: "",

                isAllow: false

            },

            phone1: "010",
            phone2: "",
            phone3: "",

            email1: "",
            email2: "",
            email3: "",

            sign: "",

            agree1: false,
            agree2: false,

            check: false,

            prependPhones: [
                { text: "010", value: "010" },
                { text: "011", value: "011" },
                { text: "016", value: "016" },
                { text: "017", value: "017" },
                { text: "019", value: "019" }
            ],
            prependEmails: [
                { text: ":: 직접입력 ::", value: "" },
                { text: "naver.com", value: "naver.com" },
                { text: "gmail.com", value: "gmail.com" },
                { text: "daum.net", value: "daum.net" },
                { text: "hanmail.net", value: "hanmail.net" },
            ],
            settings: "",

            filter: {
                code: "ko"
            }
        }
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            const { settings } = await api.v1.boards.settings.get({
                headers: this.filter
            })
            this.settings = settings[0]
        },
        validate(){
            try{
                let demo = this.demo
                if(!demo.name) throw new Error('이름 (혹은 단체 기관명)을 입력해주세요')
                if(!demo.email || !this.email1 || !this.email2) throw new Error('이메일을 입력해주세요')
                if(!demo.birthDate) throw new Error('생년월일 (혹은 사업자번호)을 입력해주세요')
                if(!demo.phone || !this.phone1 || !this.phone2 || !this.phone3) throw new Error('연락처를 입력해주세요')
                if(!demo.postcode || !demo.address1 || !demo.address2) throw new Error('주소를 입력해주세요')
                if(!demo.inquiry) throw new Error('기부금 영수증 수령을 입력해주세요')
                if(!demo.personalNum) throw new Error('주민등록번호 앞자리 (혹은 사업자등록번호)를 입력해주세요')
                if(!demo.donationiType) throw new Error('후원유형을 입력해주세요')
                if(demo.donationiType !== '기타' && demo.donationiType !== '일시후원') {
                    if(!demo.bank || !demo.accountNum || !demo.detailAddress || !demo.transferDate) throw new Error('CMS 자동이체 후원계좌정보를 입력해주세요');
                }
                if(!this.agree1) throw new Error('개인정보 수집·이용에 동의해주세요')
                if(!this.agree2) throw new Error('개인정보 제3자 제공 동의에 동의해주세요')
                if(!this.sign) throw new Error('서명을 입력해주세요')
                if(!this.check) throw new Error('확인에 체크해주세요')
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async submit(){
            if(this.validate()){
                let demo = this.demo
                this.demo.address = this.demo.address1 +this.demo.address2
                let { success } = await api.v1.message.post({ demo })
                await api.v1.forms.post({ demo })
                if(success){
                    alert('기부 및 후원 신청이 완료됐습니다.')
                    this.$router.push('/')
                }
            }
        },
        phoneNumberJoin(){
            this.demo.phone = `${this.phone1}-${this.phone2}-${this.phone3}`
        },
        emailJoin(){
            this.demo.email = `${this.email1}@${this.email2}`
        },
        searchAddress() {
            this.$refs["daum-postcode"].open();
        },
        setAddress({ postcode, address }) {
            this.demo.postcode = postcode;
            this.demo.address = address;
        },
    },
    watch:{
        email3(){
            this.emailJoin()
        },
    }
}
</script>

<style scoped>
.agree-box{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7fb;
    border-radius: 6px;
}
</style>