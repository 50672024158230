var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "시설 및 참여",
      "tabActive": "코칭스탭",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "코칭스탭",
      "tabActive": "2"
    }
  }), _c('sub-staff-tab'), _c('staff-top', [_c('v-row', [_c('v-col', {
    staticClass: "mb-50 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/center/staff/staff-06.jpg",
      "alt": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "lg": "7",
      "offset-lg": "1"
    }
  }, [_c('div', {
    staticClass: "w-100 d-sm-flex align-end justify-space-between mb-30 mb-md-70 mb-lg-110"
  }, [_c('div', [_c('p', {
    staticClass: "color-point page-text line-height-1 mb-6 mb-lg-20"
  }, [_vm._v("축구 코치")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold line-height-1 mr-8 mr-lg-20"
  }, [_vm._v("김성민")]), _c('a', {
    attrs: {
      "href": "https://www.instagram.com/uthestarsports/",
      "target": "_blank"
    }
  }, [_c('v-btn', {
    staticClass: "instar",
    attrs: {
      "large": "",
      "icon": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-insta"
  })])], 1)])]), _c('v-btn', {
    staticClass: "min-w-180px min-w-lg-240px mt-20 mt-sm-0",
    attrs: {
      "to": "/inquiry",
      "large": "",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "d-flex justify-space-between align-center w-100"
  }, [_vm._v(" 문의하기 "), _c('i', {
    staticClass: "icon icon-right-arrow"
  })])])], 1), _c('div', {
    staticClass: "mb-6 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v("학력 / 경력")])]), _c('v-col', {
    staticClass: "mt-n10 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "ml-lg-4"
  }, [_c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 광희중학교")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 통진고등학교")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· Castilla La mancha UNIV.(입학)")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 2017-18 ) CD Manchego ciudad real Juvenil A")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 2018-19) Ciudad Real CF")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 2018-19) CD Manchego Ciudad Real")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 2019-2020) Leganes U21")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 2019-2021) Rayo Ciudad Alcobendas")]), _c('p', {
    staticClass: "page-text dot-text color-grey"
  }, [_vm._v("· 2021-2022) Villaverde San Andrés")])])])], 1)], 1), _c('div', {
    staticClass: "mb-6 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v("상훈 / 자격증")])]), _c('v-col', {
    staticClass: "mt-n10 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "ml-lg-4"
  }, [_c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 스페인 축구 지도자 자격증 Nivel1")]), _c('p', {
    staticClass: "page-text dot-text color-grey"
  }, [_vm._v("· 바르셀로나 비디오 분석가 양성과정 수료")])])])], 1)], 1), _c('div', {
    staticClass: "mb-6 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v("이메일")])]), _c('v-col', {
    staticClass: "mt-n10 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "ml-lg-4"
  }, [_c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v("mininspain@uthestar.org")])])])], 1)], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }