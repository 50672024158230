var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "U the STAR",
      "tabActive": "활동사진",
      "bg": "/images/sub/visual/sv1.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [!_vm.$route.params._board ? _c('gallery-list') : _c('gallery-view')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }