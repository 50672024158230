var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "mb-50 mb-lg-72"
  }, [_c('v-tabs', [_c('v-tab', {
    on: {
      "click": function ($event) {
        _vm.filter.category = '';
      }
    }
  }, [_vm._v("전체보기")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        _vm.filter.category = '행사 사진';
      }
    }
  }, [_vm._v("행사사진")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        _vm.filter.category = '진로 체험';
      }
    }
  }, [_vm._v("진로체험")]), _c('v-tab', {
    on: {
      "click": function ($event) {
        _vm.filter.category = '대회 훈련';
      }
    }
  }, [_vm._v("대회훈련")])], 1)], 1), _c('div', {
    staticClass: "mb-12 mb-lg-24"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "w-100 w-md-120px",
    attrs: {
      "placeholder": "선택",
      "items": _vm.filterItems,
      "persistent-placeholder": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "item-text": "text",
      "item-valuie": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 w-md-300px",
    attrs: {
      "disabled": _vm.filter.searchKey == null,
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요."
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--input w-100 min-w-md-100px",
    attrs: {
      "color": "secondary",
      "dense": ""
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('div', {
    staticClass: "board-list-gallery pt-20 pt-lg-40"
  }, [_c('v-row', {
    staticClass: "my-n10 my-lg-n24"
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      staticClass: "py-10 py-lg-24",
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('div', {
      staticClass: "w-100",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${board._id}`);
        }
      }
    }, [_c('div', {
      staticClass: "thum-wrap"
    }, [_c('div', {
      staticClass: "thum",
      style: `background-image: url(${board.thumb})`
    })]), _c('h6', {
      staticClass: "page-text--lg font-weight-bold board-list-gallery__tit mt-8 mt-lg-20"
    }, [_vm._v(" " + _vm._s(board.subject) + " ")])])]);
  }), 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }