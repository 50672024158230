<template>
    <client-page>

        <sub-visual
        sh="기부 및 후원하기"
        tabActive="기부 및 후원"
        bg="/images/sub/visual/sv4.jpg">
        </sub-visual>

        <section class="section--sm section--first">
            <v-container>

                <div class="mb-50 mb-lg-72">
                    <v-tabs v-model="tab" centered center-active class="tab-col-6">
                        <v-tab>
                            지원대상 및 <br class="d-block d-sm-none">프로그램 안내
                        </v-tab>
                        <v-tab>
                            신청절차
                        </v-tab>
                    </v-tabs>
                </div>

                <v-tabs-items v-model="tab">

                    <v-tab-item>

                        <div class="pa-20 py-md-26 w-100 d-md-flex justify-center page-text--lg text-center" style="background-color: #ffe4d9">
                            <p class="color-point font-weight-bold mb-2 mb-md-0 mr-md-8 mr-lg-16">지원대상</p>
                            <p>품행이 단정하고 타 학생 및 학교생활에 성실하고 모범이 되는 아동(청소년)</p>
                        </div>

                        <div class="pa-20 pa-md-30 px-lg-50 py-lg-60 w-100" style="background-color: #fff8f6">
                            <div class="mb-20 mb-lg-54">
                                <div class="d-flex align-center mb-6 mb-lg-18">
                                    <div class="num d-flex align-center justify-center text-center bg-point color-white font-weight-bold page-text--sm mr-8 mr-lg-16">
                                        01
                                    </div>
                                    <h5 class="tit--sm font-weight-bold color-point">
                                        취약계층 아동(청소년) 지원
                                    </h5>
                                </div>
                                <div class="pl-38 pl-lg-52">
                                    <h6 class="page-text--lg font-weight-bold mb-4 mb-lg-10">
                                        체육 활동 지원 프로그램
                                    </h6>
                                    <p class="dot-text color-grey page-text">
                                        · 형편상 체육활동이 어려운 아동, 청소년들의 체육 및 체육 활동 시설 지원
                                    </p>
                                    <p class="dot-text color-grey page-text mb-6 mb-lg-22">
                                        · 민주화 운동 관련 국가유공자(상훈수여자) 및 애국지사 후손 등에게 스포츠 경기 관람 및 국내외 스포츠 캠프 참여등 체험 기회를 부여
                                    </p>
                                    <h6 class="page-text--lg font-weight-bold mb-4 mb-lg-10">
                                        외부기관 지원 장학금 프로그램
                                    </h6>
                                    <p class="dot-text color-grey page-text">
                                        · 형편상 체육활동이 어려운 청소년에게 체육 활동 지원을 위해 체육 관련 외부 기관을 통해 지원
                                    </p>
                                    <p class="dot-text color-grey page-text">
                                        · 기타 장학금 : 위 내용 이외에 재단의 설립 목적(사업)에 부합되는 경우 (이사회의 의결을 거쳐 장학금 지급 검토)
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex align-center mb-6 mb-lg-18">
                                    <div class="num d-flex align-center justify-center text-center bg-point color-white font-weight-bold page-text--sm mr-8 mr-lg-16">
                                        02
                                    </div>
                                    <h5 class="tit--sm font-weight-bold color-point">
                                        엘리트 유소년(선수) 지원
                                    </h5>
                                </div>
                                <div class="pl-38 pl-lg-52">
                                    <h6 class="page-text--lg font-weight-bold mb-4 mb-lg-10">
                                        특기개발 장학금 후원 프로그램
                                    </h6>
                                    <p class="dot-text color-grey page-text">
                                        · 재능(엘리트)이 있는 아동(청소년)들의 특기 개발 지원. 실전 테스트 통한 재능 (초기)발굴 및 (향후)개발 가능
                                    </p>
                                </div>
                            </div>
                        </div>

                    </v-tab-item>

                    <v-tab-item>

                        <div class="pa-20 py-md-26 w-100 page-text--lg text-center" style="background-color: #ffe4d9">
                            <p class="color-point font-weight-bold">장학생 지원자는 지정된 기일 내에 아래의 서류를 제출하여야 합니다.</p>
                        </div>

                        <div class="pa-20 pa-md-30 px-lg-50 py-lg-60 w-100 page-text" style="background-color: #fff8f6">
                            <p class="mb-2 mb-lg-8">
                                01. (장학금)지급 혹은 체육활동 지원 신청서(사진 첨부 장학금일 경우)
                            </p>
                            <p class="mb-2 mb-lg-8">
                                02. 추천서(학교장 또는 지방자치단체장)
                            </p>
                            <p class="mb-2 mb-lg-8">
                                03. 자기소개서 (혹은 단체소개서)
                            </p>
                            <p class="mb-2 mb-lg-8">
                                04. 가족관계증명서
                            </p>
                            <p class="mb-2 mb-lg-8">
                                05. 주민등록등본
                            </p>
                            <p class="mb-2 mb-lg-8">
                                06. 장학금 지급 계좌 사본(보호자 또는 학교명의 계좌)
                            </p>
                            <p>
                                07. 기타 장학생 선정을 위해 추가로 필요하다고 인정되는 서류
                            </p>
                        </div>

                    </v-tab-item>

                </v-tabs-items>

                <div class="mt-50 mt-lg-74">
                    <h5 class="tit--sm font-weight-bold mb-10 mb-lg-26">
                        기부 및 후원 형태
                    </h5>

                    <div class="w-100 border border-2 border-lg-4 border-color-light pa-20 pa-md-30 px-lg-10 py-lg-54">
                        <v-row class="mx-lg-0">
                            <v-col cols="12" sm="6" lg="3" xl="auto" class="px-lg-40 px-xl-50 dona-item">
                                <p class="page-text--lg font-weight-bold mb-2 mb-lg-14">
                                    일반기부
                                </p>
                                <p class="page-text color-grey">
                                    현금출자 / 일시불 형식
                                </p>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" xl="auto" class="px-lg-40 px-xl-50 dona-item">
                                <p class="page-text--lg font-weight-bold mb-2 mb-lg-14">
                                    재능기부
                                </p>
                                <p class="page-text color-grey">
                                    멘토링 기부 / 프로선수 <br class="d-none d-lg-block">
                                    비시즌 멘토링 수업
                                </p>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" xl="auto" class="px-lg-40 px-xl-50 dona-item">
                                <p class="page-text--lg font-weight-bold mb-2 mb-lg-14">
                                    봉사활동기부
                                </p>
                                <p class="page-text color-grey">
                                    병역시간, 봉사시간 / 병역 <br class="d-none d-lg-block">
                                    및 봉사활동 멘토링 수업
                                </p>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" xl="auto" class="px-lg-40 px-xl-50 dona-item">
                                <p class="page-text--lg font-weight-bold mb-2 mb-lg-14">
                                    용품후원
                                </p>
                                <p class="page-text color-grey">
                                    용품업체 용품 지급 후원 / <br class="d-none d-lg-block">
                                    의류 및 음료 스포츠 관련 <br class="d-none d-lg-block">
                                    장비 후원
                                </p>
                            </v-col>
                        </v-row>
                    </div>
                </div>

            </v-container>
        </section>

        <section class="section--sm section--last">
            <v-container>
                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold">
                        기부 및 후원 신청
                    </h3>
                </div>
            </v-container>
            <form-mail></form-mail>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import FormMail from "@/components/client/ko/donation/form-mail.vue"
export default {
    components: {
        ClientPage,
        SubVisual,
        FormMail
    },
    data () {
      return {
        tab: null,
      }
    },
};
</script>

<style lang="scss" scoped>
.num{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .num{
    width: 36px;
    height: 36px;
    border-radius: 50%;
}
}
@media (min-width:1200px){
}

</style>