var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "business-item pa-20 pa-md-30 py-lg-44 px-lg-50 d-flex"
  }, [_c('h5', {
    staticClass: "tit--sm w-40px mr-10 font-weight-bold color-point"
  }, [_vm._v(" " + _vm._s(_vm.num) + " ")]), _c('div', [_c('h5', {
    staticClass: "tit--sm font-weight-bold mb-8 mb-lg-14"
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]), _vm._t("default")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }