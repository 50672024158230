<template>
    <client-page>
        <sub-visual sh="시설 및 참여" tabActive="시설소개" bg="/images/sub/visual/sv2.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <dept3-tab page="시설소개" tabActive="3"></dept3-tab>

                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold">
                        그라운드
                    </h3>
                </div>

                <div class="mb-50 mb-lg-80">
                    <center-top sc1="‘블루팟 그라운드(BLUE POT GROUND)’는 ‘블루스폿(BLUE SPOT)’ 에서 서서히 완성되어지는 곳을 뜻합니다." sc2="다양한 종목을 통해 화합과 단결의 구심점이 되고, 아이들과 함께 더 많이 운동 할 수 있는 공간을 제공합니다.">
                        <slide-primary slideNum="1" className="pagination-top">
                            <div class="swiper-slide">
                                <img src="/images/sub/center/ground/s1-img1.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/ground/s1-img2.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/ground/s1-img3.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/ground/s1-img4.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/ground/s1-img5.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/ground/s1-img6.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/ground/s1-img7.jpg" alt="" class="d-block w-100" />
                            </div>
                        </slide-primary>
                    </center-top>
                </div>

                <div class="mb-50 mb-lg-80">
                    <v-row>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="01">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/ground/s1-icon1.png" maxWidth="44"> </icon-primary>
                                <p class="page-text">
                                    축구·야구·농구 등 <br class="d-none d-lg-block" />
                                    신나게 마음껏 뛰어 <br class="d-none d-lg-block" />
                                    놀 수 있는 운동장
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="02">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/ground/s1-icon2.png" maxWidth="49"> </icon-primary>
                                <p class="page-text">
                                    35mm의 섬세하고 <br class="d-none d-lg-block" />
                                    실감나는 잔디구장, <br class="d-none d-lg-block" />
                                    그리고 70m의 거리감
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-sm-0">
                            <num-item num="03">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/ground/s1-icon3.png" maxWidth="42"> </icon-primary>
                                <p class="page-text">
                                    여러 명의 아이들이 <br class="d-none d-lg-block" />
                                    다양한 운동을 함께 <br class="d-none d-lg-block" />
                                    즐길 수 있는 곳
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <num-item num="04">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/ground/s1-icon4.png" maxWidth="44"> </icon-primary>
                                <p class="page-text">
                                    단체 대관 OK! <br />
                                    프라이빗한 우리만의 <br class="d-none d-lg-block" />
                                    그라운드 사용
                                </p>
                            </num-item>
                        </v-col>
                    </v-row>
                </div>

                <div class="mb-50 mb-lg-74 w-100">
                    <v-img src="/images/sub/center/ground/map.jpg" class="w-100"></v-img>
                </div>

                <h5 class="tit--sm font-weight-bold mb-10 mb-lg-26">찾아 오시는 길</h5>

                <div class="pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4">
                    <div class="d-lg-flex align-center mb-12 mb-lg-20">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            주소
                        </p>
                        <p class="color-grey page-text">
                            15722 서울시 강서구 방화동 41-4 강서구립방화테니스장 옆
                        </p>
                    </div>
                    <div class="d-lg-flex align-center">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            대중교통
                        </p>
                        <div class="d-flex page-text">
                            <p class="font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14">
                                버스
                            </p>
                            <div class="d-flex align-center">
                                <span class="dot mr-4 mr-lg-10" style="background-color: #60b73c"></span>
                                <p class="color-grey">
                                    마을버스 강서07 ‘생태공원’ 하차
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
import NumItem from "@/components/client/ko/sub/sub-num-item.vue";
import SlidePrimary from "@/components/client/ko/sub/sub-slide-primary.vue";
import CenterTop from "@/components/client/ko/sub/sub-center-top.vue";
import IconPrimary from "@/components/client/ko/sub/sub-icon-primary.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
        NumItem,
        SlidePrimary,
        CenterTop,
        IconPrimary,
    },
};
</script>

<style lang="scss" scoped></style>
