var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "시설 및 참여",
      "tabActive": "프로그램 안내",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section--sm section--first section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v("스포츠 아카데미 프로그램")])]), _c('v-img', {
    staticClass: "d-block d-md-none mb-10 mx-auto",
    attrs: {
      "src": "/images/common/scroll.png",
      "max-width": "290"
    }
  }), _c('div', {
    staticClass: "table-responsive mb-50 mb-74"
  }, [_c('table', {
    staticClass: "client-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "table-split"
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("서울본점")])]), _c('th', {
    staticClass: "table-split"
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("인천(청라)")])]), _c('th', {
    staticClass: "table-split"
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("하남")])])])]), _c('tbody', [_c('tr', [_c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("재능기부 및 진로체험")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("재능기부 및 진로체험")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("재능기부")])])]), _c('tr', [_c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v(" 유소년 취미반 (야구, 축구) "), _c('br'), _vm._v(" 주중반 "), _c('br'), _vm._v(" 주말반 ")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v(" 유소년 취미반 (야구) "), _c('br'), _vm._v(" 주중반 "), _c('br'), _vm._v(" 주말반 ")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v(" 리틀 야구단 "), _c('br'), _vm._v(" 취미반 / 선수반 모집 ")])])]), _c('tr', [_c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_c('p', [_vm._v(" 종목 "), _c('strong', [_vm._v("야구")]), _vm._v(" 문의하기 : 010-5823-2592 "), _c('br'), _vm._v(" 종목 "), _c('strong', [_vm._v("축구")]), _vm._v(" 문의하기 : 010-5060-8723 ")])])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_c('p', [_vm._v("종목 "), _c('strong', [_vm._v("야구")]), _vm._v(" 문의하기 : 010-8961-2389")])])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_c('p', [_vm._v("종목 "), _c('strong', [_vm._v("야구")]), _vm._v(" 문의하기 : 010-3716-3589")])])])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v(" 취미반은 초등학생 대상입니다."), _c('br'), _vm._v(" 주중 취미반 셔틀버스 일부운행 "), _c('br'), _vm._v(" 주말 취미반은 셔틀버스를 운행하지 않습니다. ")])])])])])]), _c('v-img', {
    staticClass: "d-block d-md-none mb-10 mx-auto",
    attrs: {
      "src": "/images/common/scroll.png",
      "max-width": "290"
    }
  }), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "client-table"
  }, [_c('colgroup', [_c('col', {
    staticStyle: {
      "width": "20%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "20%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "20%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "20%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "20%"
    }
  })]), _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("구분")])]), _c('th', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("기간 및 횟수")])]), _c('th', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("가격")])]), _c('th', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("비고")])])])]), _c('tbody', [_c('tr', {
    staticClass: "bg-point"
  }, [_c('th', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("차상위계층청소년")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("개인 혹은 단체")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("월 4회")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("스포츠바우처")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("1인당 환산시 ₩95,000")])])]), _c('tr', [_c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("취미반")])]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("주중, 주말")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("월 4회")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("₩140,000")])]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("출연가격의 30% 차상위 계층 아이들의 스포츠 교육을 위한 기부 후원금 처리가 됩니다.")])])]), _c('tr', [_c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("월 8회")])]), _c('td', {
    staticClass: "border-end"
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("₩200,000")])])]), _c('tr', [_c('th', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("사회인 레슨")])]), _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("방문 또는 이메일 문의")])])]), _c('tr', [_c('th', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("대관")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("2시간 기준")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("1회")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("₩200,000")])]), _c('td', [_c('div', {
    staticClass: "table-inner"
  }, [_vm._v("0코치 + ₩100,000 추가")])])])])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }