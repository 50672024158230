<template>
    <client-page>
        <sub-visual sh="시설 및 참여" tabActive="시설소개" bg="/images/sub/visual/sv2.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <dept3-tab page="시설소개" tabActive="2"></dept3-tab>

                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold">
                        필드
                    </h3>
                </div>

                <div class="mb-50 mb-lg-80">
                    <center-top sc1="‘블루스폿 필드 (BLUE SPOT FIELD)’는 아이들의 완성 되어지는 과정을 우리가 함께 키워나가는 곳입니다." sc2="지금까지 내가 경험할 수 없었던 스포츠를 안전하고 친환경적인 장소에서 함께 합니다.">
                        <slide-primary slideNum="1" className="pagination-top">
                            <div class="swiper-slide">
                                <img src="/images/sub/center/field/s1-img1.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/field/s1-img3.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/field/s1-img4.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/field/s1-img5.jpg" alt="" class="d-block w-100" />
                            </div>
                        </slide-primary>
                    </center-top>
                </div>

                <div class="mb-50 mb-lg-80">
                    <v-row>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="01">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/field/s1-icon1.png" maxWidth="53"> </icon-primary>
                                <p class="page-text">
                                    모든 종목 레슨 최고 시설! <br />
                                    실내,외 기능 다 갖춤. <br />
                                    트레이닝 실(샤워실) 완비
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="02">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/field/s1-icon2.png" maxWidth="31"> </icon-primary>
                                <p class="page-text">
                                    접근성 최고! 서울시 <br class="d-none d-lg-block" />
                                    강서구 발산역 3분 거리 <br class="d-none d-lg-block" />
                                    주차시설 완비
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-sm-0">
                            <num-item num="03">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/field/s1-icon3.png" maxWidth="49"> </icon-primary>
                                <p class="page-text">
                                    20m 거리 롱패스도 거뜬! <br />
                                    넓은 공간과 부상 방지를 <br class="d-none d-lg-block" />
                                    위한 잔디설계
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <num-item num="04">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/field/s1-icon4.png" maxWidth="40"> </icon-primary>
                                <p class="page-text">
                                    탁 트인 넓은 시야와 시원한 <br class="d-none d-lg-block" />
                                    타격감! 8m 높이의 안전 <br class="d-none d-lg-block" />
                                    펜스로 위험 걱정 NO!
                                </p>
                            </num-item>
                        </v-col>
                    </v-row>
                </div>

                <div class="mb-50 mb-lg-74 w-100">
                    <v-img src="/images/sub/center/field/map.jpg" class="w-100"></v-img>
                </div>

                <h5 class="tit--sm font-weight-bold mb-10 mb-lg-26">찾아 오시는 길</h5>

                <div class="pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4">
                    <div class="d-lg-flex align-center mb-12 mb-lg-20">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            주소
                        </p>
                        <p class="color-grey page-text">
                            07591 서울시 강서구 공항대로 39길 7 (등촌동, 재단법인 유더스타스포츠)
                        </p>
                    </div>
                    <div class="d-lg-flex align-center">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            대중교통
                        </p>
                        <div class="d-lg-flex align-center page-text">
                            <div class="d-flex">
                                <p class="font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14">
                                    버스
                                </p>
                                <div class="d-flex align-center">
                                    <span class="dot mr-2 mr-lg-4" style="background-color: #4b80ea"></span>
                                    <span class="dot mr-4 mr-lg-10" style="background-color: #04aeab"></span>
                                    <p class="color-grey">
                                        ‘한국가스기술공사(중)’ 하차
                                    </p>
                                </div>
                            </div>
                            <span class="line mx-22 d-none d-lg-block"></span>
                            <div class="d-flex">
                                <p class="font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14">
                                    지하철
                                </p>
                                <div class="d-flex align-center">
                                    <span class="dot mr-4 mr-lg-10" style="background-color: #833edb"></span>
                                    <p class="color-grey">
                                        5호선 발산역 3,4번 출구
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
import NumItem from "@/components/client/ko/sub/sub-num-item.vue";
import SlidePrimary from "@/components/client/ko/sub/sub-slide-primary.vue";
import CenterTop from "@/components/client/ko/sub/sub-center-top.vue";
import IconPrimary from "@/components/client/ko/sub/sub-icon-primary.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
        NumItem,
        SlidePrimary,
        CenterTop,
        IconPrimary,
    },
};
</script>

<style lang="scss" scoped></style>
