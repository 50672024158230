var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "시설 및 참여",
      "tabActive": "시설소개",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "시설소개",
      "tabActive": "3"
    }
  }), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" 그라운드 ")])]), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('center-top', {
    attrs: {
      "sc1": "‘블루팟 그라운드(BLUE POT GROUND)’는 ‘블루스폿(BLUE SPOT)’ 에서 서서히 완성되어지는 곳을 뜻합니다.",
      "sc2": "다양한 종목을 통해 화합과 단결의 구심점이 되고, 아이들과 함께 더 많이 운동 할 수 있는 공간을 제공합니다."
    }
  }, [_c('slide-primary', {
    attrs: {
      "slideNum": "1",
      "className": "pagination-top"
    }
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/ground/s1-img1.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/ground/s1-img2.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/ground/s1-img3.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/ground/s1-img4.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/ground/s1-img5.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/ground/s1-img6.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/ground/s1-img7.jpg",
      "alt": ""
    }
  })])])], 1)], 1), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "01"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/ground/s1-icon1.png",
      "maxWidth": "44"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 축구·야구·농구 등 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 신나게 마음껏 뛰어 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 놀 수 있는 운동장 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "02"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/ground/s1-icon2.png",
      "maxWidth": "49"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 35mm의 섬세하고 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 실감나는 잔디구장, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 그리고 70m의 거리감 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "03"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/ground/s1-icon3.png",
      "maxWidth": "42"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 여러 명의 아이들이 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 다양한 운동을 함께 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 즐길 수 있는 곳 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "04"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/ground/s1-icon4.png",
      "maxWidth": "44"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 단체 대관 OK! "), _c('br'), _vm._v(" 프라이빗한 우리만의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 그라운드 사용 ")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mb-50 mb-lg-74 w-100"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/center/ground/map.jpg"
    }
  })], 1), _c('h5', {
    staticClass: "tit--sm font-weight-bold mb-10 mb-lg-26"
  }, [_vm._v("찾아 오시는 길")]), _c('div', {
    staticClass: "pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4"
  }, [_c('div', {
    staticClass: "d-lg-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 주소 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" 15722 서울시 강서구 방화동 41-4 강서구립방화테니스장 옆 ")])]), _c('div', {
    staticClass: "d-lg-flex align-center"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 대중교통 ")]), _c('div', {
    staticClass: "d-flex page-text"
  }, [_c('p', {
    staticClass: "font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14"
  }, [_vm._v(" 버스 ")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "dot mr-4 mr-lg-10",
    staticStyle: {
      "background-color": "#60b73c"
    }
  }), _c('p', {
    staticClass: "color-grey"
  }, [_vm._v(" 마을버스 강서07 ‘생태공원’ 하차 ")])])])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }