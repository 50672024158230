<template>
    <client-page>
        <sub-visual sh="U the STAR" tabActive="재단소개" bg="/images/sub/visual/sv1.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <dept3-tab page="재단소개" tabActive="2"></dept3-tab>

                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold mb-28 mb-lg-48">인천(청라)지사</h3>
                    <h5 class="tit--sm font-weight-bold color-point mb-8 mb-lg-14">아시안 게임을 개최한 국내 3대 도시 “인천”</h5>
                    <p class="page-text">이제 인천의 아이들을 위한 체육 봉사 활동을 시작하려고 합니다.</p>
                </div>

                <div class="mb-50 mb-lg-80">
                    <slide-primary slideNum="2" className="pagination-bottom">
                        <div class="swiper-slide">
                            <img src="/images/sub/center/square/s1-img1.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/center/square/s1-img2.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/center/square/s1-img3.jpg" alt="" class="d-block w-100" />
                        </div>
                        <!-- <div class="swiper-slide">
                            <img src="/images/sub/center/square/s1-img4.jpg" alt="" class="d-block w-100" />
                        </div> -->
                        <div class="swiper-slide">
                            <img src="/images/sub/center/square/s1-img5.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/center/square/s1-img6.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/center/square/s1-img7.jpg" alt="" class="d-block w-100" />
                        </div>
                    </slide-primary>
                </div>

                <div class="mb-30 mb-lg-40">
                    <v-row>
                        <v-col cols="12" lg="4" class="mb-10 mb-lg-0">
                            <num-item num="01">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">스포츠 교육</h5>
                                <p class="page-text">
                                    교육부 진로체험 <br class="d-none d-lg-block" />
                                    프로그램 연계
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" lg="4" class="mb-10 mb-lg-0">
                            <num-item num="02">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">진로체험 프로그램</h5>
                                <p class="page-text">
                                    인천지역 초,중,고 <br class="d-none d-lg-block" />
                                    정규 체육수업 포함
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <num-item num="03">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">스포츠 바우처 활용</h5>
                                <p class="page-text">
                                    차상위 계층 아이들의 <br class="d-none d-lg-block" />
                                    공평한 체육활동 제공
                                </p>
                            </num-item>
                        </v-col>
                        <!-- <v-col cols="6" lg="3">
                            <num-item num="04">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">프로스포츠 연계</h5>
                                <p class="page-text">
                                    인천 프로스포츠 구단과 <br class="d-none d-lg-block" />
                                    연계, 그리고 다양한 공익 <br class="d-none d-lg-block" />
                                    활동 예고
                                </p>
                            </num-item>
                        </v-col> -->
                    </v-row>
                </div>

                <div class="w-100 py-14 border-top border-bottom mb-50 mb-lg-72">
                    <p class="page-text text-center color-grey">* 재능 기부(체육 관련) 혹은 각종 후원 활동을 해주실 다양한 인재를 찾습니다.</p>
                </div>

                <h5 class="tit--sm font-weight-bold mb-10 mb-lg-26">문의하기</h5>

                <div class="pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4 bg-white">
                    <div class="d-flex align-center mb-12 mb-lg-20">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold">문의</p>
                        <a href="/inquiry" class="d-block" style="max-width: calc(100% - 72px)">
                            <v-img src="/images/sub/about/own/inquiry-button.png"></v-img>
                        </a>
                    </div>
                    <div class="d-flex align-center">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold">이메일</p>
                        <a href="mailto:choiborn@uthestar.org" class="d-block color-grey page-text"> wonjin6083@uthestar.org </a>
                    </div>
                </div>
            </v-container>
        </section>

        <section class="section bg-grey">
            <v-container>
                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold mb-28 mb-lg-48">(경기도)하남지사</h3>
                    <h5 class="tit--sm font-weight-bold color-point mb-8 mb-lg-14">새로운 스포츠 도시를 꿈꾸다. "하남"</h5>
                    <p class="page-text">
                        세상의 미래가 될 우리 아이들이 꿈꾸는 스포츠 신도시 "하남"에서 <br />
                        재단법인 유더스타스포츠의 활동이 시작됩니다.
                    </p>
                </div>

                <div class="mb-50 mb-lg-80">
                    <slide-primary slideNum="1" className="pagination-bottom">
                        <div class="swiper-slide">
                            <img src="/images/sub/about/own/s1-img1.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/about/own/s1-img2.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/about/own/s1-img3.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/about/own/s1-img4.jpg" alt="" class="d-block w-100" />
                        </div>
                        <div class="swiper-slide">
                            <img src="/images/sub/about/own/s1-img5.jpg" alt="" class="d-block w-100" />
                        </div>
                    </slide-primary>
                </div>

                <div class="mb-30 mb-lg-40">
                    <v-row>
                        <v-col cols="12" lg="4" class="mb-10 mb-lg-0">
                            <num-item num="01">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">스포츠 교육</h5>
                                <p class="page-text">
                                    교육부 진로체험 <br class="d-none d-lg-block" />
                                    프로그램 연계
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" lg="4" class="mb-10 mb-sm-0">
                            <num-item num="02">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">스포츠 바우처 활용</h5>
                                <p class="page-text">
                                    차상위 계층 아이들의 <br class="d-none d-lg-block" />
                                    공평한 체육활동 제공
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <num-item num="03">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">스포츠 재능기부</h5>
                                <p class="page-text">
                                    하남리틀야구단 출신 <br class="d-none d-lg-block" />
                                    프로선수를 중심으로 <br class="d-none d-lg-block" />
                                    아동청소년등 체육사각지역 <br class="d-none d-lg-block" />
                                    아이들 체육재능기부
                                </p>
                            </num-item>
                        </v-col>
                        <!-- <v-col cols="12" lg="3">
                            <num-item num="04">
                                <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">프로스포츠 연계</h5>
                                <p class="page-text">
                                    인천 프로스포츠 구단과 <br class="d-none d-lg-block" />
                                    연계, 그리고 다양한 공익 <br class="d-none d-lg-block" />
                                    활동 예고
                                </p>
                            </num-item>
                        </v-col> -->
                    </v-row>
                </div>

                <div class="w-100 py-14 border-top border-bottom mb-50 mb-lg-72">
                    <p class="page-text text-center color-grey">* 재능(체육관련)기부 및 각종 후원활동을 함께해줄 다양한 멘토를 찾습니다.</p>
                </div>

                <h5 class="tit--sm font-weight-bold mb-10 mb-lg-26">문의하기</h5>

                <div class="pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4">
                    <div class="d-flex align-center mb-12 mb-lg-20">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold">문의</p>
                        <a href="/inquiry" class="d-block" style="max-width: calc(100% - 72px)">
                            <v-img src="/images/sub/about/own/inquiry-button.png"></v-img>
                        </a>
                    </div>
                    <div class="d-flex align-center">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mr-12 mr-lg-40 font-weight-bold">이메일</p>
                        <a href="mailto:suwon@uthestar.org" class="d-block color-grey page-text">hanam@uthestar.org</a>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
import NumItem from "@/components/client/ko/sub/sub-num-item.vue";
import SlidePrimary from "@/components/client/ko/sub/sub-slide-primary.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
        NumItem,
        SlidePrimary,
    },
};
</script>

<style lang="scss" scoped></style>
