<template>
    <div class="sub-visual-wrap mt-50 mt-lg-0 fade">
        <div class="sub-visual pt-lg-100 pb-lg-60">
            <div class="sub-visual-tit">
                <v-container>
                    <h2>{{sh}}</h2>
                </v-container>
            </div>
            <div class="sub-visual-bg" :style="'background-image:url('+ bg +');'">
            </div>
        </div>
        <v-container>
            <client-gnb className="sub-tab-wrap" :tabActive="tabActive" :sh="sh" v-if="sh !== '문의하기' || '개인정보처리방침' || '개인정보 제3자 제공 동의'"></client-gnb>
        </v-container>
    </div>
</template>

<script>
import ClientGnb from "@/components/client/ko/inc/client-gnb.vue";

export default{
    props : {
        sh : {type :String, default : ""},
        bg : {type :String, default : ""},
        tabActive : {type :String, default : ""},
    },
    components: {
        ClientGnb,
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	// mounted() {
	// 	this.init();
	// },
	// methods : {
	// 	init : function() {
    //         setTimeout(() => {
    //             AOS.init({
    //                 once: false,
    //                 offset: 240,
    //                 duration: 500,
    //                 throttleDelay : 100,
    //                 easing : 'ease-in-out',
    //                 anchorPlacement : 'top-center',
    //             });
    //         }, 100);
	// 	}
	// },
}
</script>

<style scoped>
.sub-visual{
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}
.sub-visual-tit{
    position: relative;
    z-index: 1;
}
.sub-visual-tit h2{
    font-size: 3.6rem;
    line-height: 1.2;
    font-weight: 600;
    color: #fff;
    text-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
}
.sub-visual-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .sub-visual{
        height: 280px;
    }
    .sub-visual-tit h2{
        font-size: 4.6rem;
    }
}
@media (min-width:1024px){
    .sub-visual{
        height: 480px;
    }
    .sub-visual-tit h2{
        font-size: 6rem;
        text-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }
}
@media (min-width:1200px){
}

</style>