var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'sub-tab-wrap' ? _c('div', {
    staticClass: "mt-lg-n60"
  }, [_vm._l(_vm.gnbs, function (item) {
    return [item.title == _vm.sh ? _c('ul', {
      key: item.title,
      staticClass: "tab tab--block"
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title,
        staticClass: "tab__li",
        class: {
          active: _vm.tabActive == child.title
        }
      }, [_c('a', {
        staticClass: "tab__btn",
        attrs: {
          "href": child.path
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])])]);
    }), 0) : _vm._e()];
  })], 2) : _vm.className == 'gnb' ? _c('ul', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [!item.children ? _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_vm.isMobile ? _c('div', {
      class: _vm.className + '__link gnb__link--toggle'
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]) : _c('router-link', {
      class: _vm.className + '__link gnb__link--toggle',
      attrs: {
        "to": item.path
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('ul', {
      class: _vm.className + '__sub'
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title
      }, [_c('router-link', {
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))])], 1);
    }), 0)], 1)];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }