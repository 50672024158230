<template>
    <div :class="'rounded bg-grey w-80px w-lg-100px h-80px h-lg-100px d-flex align-center justify-center ' + className">
        <v-img :src="icon" :max-width="maxWidth"></v-img>
    </div>
</template>

<script>

export default{
    props : {
        className : {type :String, default : ""},
        icon : {type :String, default : ""},
        maxWidth : {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	// mounted() {
	// 	this.init();
	// },
	// methods : {
	// 	init : function() {
    //         setTimeout(() => {
    //             AOS.init({
    //                 once: false,
    //                 offset: 240,
    //                 duration: 500,
    //                 throttleDelay : 100,
    //                 easing : 'ease-in-out',
    //                 anchorPlacement : 'top-center',
    //             });
    //         }, 100);
	// 	}
	// },
}
</script>

<style scoped>
</style>