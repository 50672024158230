<template>
    <client-page>
        <sub-visual sh="시설 및 참여" tabActive="시설소개" bg="/images/sub/visual/sv2.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <dept3-tab page="시설소개" tabActive="1"></dept3-tab>

                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold">
                        스퀘어
                    </h3>
                </div>

                <div class="mb-50 mb-lg-80">
                    <center-top sc1="‘블루스폿 스퀘어 (BLUESPOT SQUARE)’는 더 많은 아이들의 또 다른 시작을 의미합니다." sc2="지금까지 내가 경험할 수 없었던 스포츠를 안전하고 친환경적인 장소에서 함께 합니다.">
                        <slide-primary slideNum="1" className="pagination-top">
                            <div class="swiper-slide">
                                <img src="/images/sub/center/square/s1-img1.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/square/s1-img2.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/square/s1-img3.jpg" alt="" class="d-block w-100" />
                            </div>
                            <!-- <div class="swiper-slide">
                                <img src="/images/sub/center/square/s1-img4.jpg" alt="" class="d-block w-100" />
                            </div> -->
                            <div class="swiper-slide">
                                <img src="/images/sub/center/square/s1-img5.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/square/s1-img6.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/square/s1-img7.jpg" alt="" class="d-block w-100" />
                            </div>
                        </slide-primary>
                    </center-top>
                </div>

                <div class="mb-50 mb-lg-80">
                    <v-row>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="01">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/square/s1-icon1.png" maxWidth="48"> </icon-primary>
                                <p class="page-text">
                                    청라 유일의<br />
                                    실내(야구,축구)<br />
                                    레슨장 완비
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="02">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/square/s1-icon2.png" maxWidth="49"> </icon-primary>
                                <p class="page-text">
                                    35mm의<br />
                                    섬세하고 안전한<br />
                                    실내 잔디
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-sm-0">
                            <num-item num="03">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/square/s1-icon3.png" maxWidth="31"> </icon-primary>
                                <p class="page-text">
                                    주차걱정 NO!<br />
                                    주차타워 내<br />
                                    체육관 입점
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <num-item num="04">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/square/s1-icon4.png" maxWidth="54"> </icon-primary>
                                <p class="page-text">
                                    미세먼지 걱정 NO!<br />
                                    날씨로부터 자유로운<br />
                                    운동 가능
                                </p>
                            </num-item>
                        </v-col>
                    </v-row>
                </div>

                <div class="mb-50 mb-lg-74 w-100">
                    <v-img src="/images/sub/center/square/map.jpg" class="w-100"></v-img>
                </div>

                <h5 class="tit--sm font-weight-bold mb-10 mb-lg-26">찾아 오시는 길</h5>

                <div class="pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4">
                    <div class="d-lg-flex align-center mb-12 mb-lg-20">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            주소
                        </p>
                        <p class="color-grey page-text">
                            22766 인천시 서구 보석로 12번안길 1, 1층 (청라주차타워)
                        </p>
                    </div>
                    <div class="d-lg-flex align-center">
                        <p class="w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            대중교통
                        </p>
                        <div class="d-lg-flex align-center page-text">
                            <div class="d-flex">
                                <p class="font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14">
                                    버스
                                </p>
                                <div class="d-flex align-center">
                                    <p class="d-flex color-grey">
                                        심곡천 하차 (간선 7), 자이더테라스 1단지 정류장 (간선 7, 간선 44, 인천e음84)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
import NumItem from "@/components/client/ko/sub/sub-num-item.vue";
import SlidePrimary from "@/components/client/ko/sub/sub-slide-primary.vue";
import CenterTop from "@/components/client/ko/sub/sub-center-top.vue";
import IconPrimary from "@/components/client/ko/sub/sub-icon-primary.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
        NumItem,
        SlidePrimary,
        CenterTop,
        IconPrimary,
    },
};
</script>

<style lang="scss" scoped></style>
