import { render, staticRenderFns } from "./StaffPage2.vue?vue&type=template&id=b90f7368&scoped=true"
import script from "./StaffPage2.vue?vue&type=script&lang=js"
export * from "./StaffPage2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b90f7368",
  null
  
)

export default component.exports