<template>
    <client-page>
        <sub-visual sh="시설 및 참여" tabActive="시설소개" bg="/images/sub/visual/sv2.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <dept3-tab page="시설소개" tabActive="4"></dept3-tab>

                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold">
                        대만 가오슝 국경·칭푸
                    </h3>
                </div>

                <div class="mb-50 mb-lg-80">
                    <center-top sc1="대만 가오슝 국경 칭푸A, 국경 칭푸B 2개면의 프로야구 정식 규격 규모의 야구장 시설을 갖춘 대만 가오슝 칭푸의 야구장 시설입니다." sc2="400m거리의 시설로 프로/ 아마추어 야구단 전지 훈련 및 관람이 가능합니다.">
                        <slide-primary slideNum="1" className="pagination-top">
                            <div class="swiper-slide">
                                <img src="/images/sub/center/taiwan/s1-img1.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/taiwan/s1-img2.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/taiwan/s1-img3.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/taiwan/s1-img4.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/taiwan/s1-img5.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/taiwan/s1-img6.jpg" alt="" class="d-block w-100" />
                            </div>
                            <div class="swiper-slide">
                                <img src="/images/sub/center/taiwan/s1-img7.jpg" alt="" class="d-block w-100" />
                            </div>
                        </slide-primary>
                    </center-top>
                </div>

                <div class="mb-50 mb-lg-80">
                    <v-row>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="01">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/taiwan/s1-icon1.png" maxWidth="44"> </icon-primary>
                                <p class="page-text">
                                    야구장 독점사용! <br />
                                    이용시간과 요일에 <br class="d-none d-lg-block" />
                                    제한 받지 않음
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-lg-0">
                            <num-item num="02">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/taiwan/s1-icon2.png" maxWidth="38"> </icon-primary>
                                <p class="page-text">
                                    대만팀과 경기! <br />
                                    연습경기 주선 및 <br class="d-none d-lg-block" />
                                    초청이 수월
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3" class="mb-10 mb-sm-0">
                            <num-item num="03">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/taiwan/s1-icon3.png" maxWidth="45"> </icon-primary>
                                <p class="page-text">
                                    다양한 부대시설! <br />
                                    자유로운 시설보강 <br class="d-none d-lg-block" />
                                    및 설계조율
                                </p>
                            </num-item>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <num-item num="04">
                                <icon-primary className="mb-10 mb-lg-20" icon="/images/sub/center/taiwan/s1-icon4.png" maxWidth="52"> </icon-primary>
                                <p class="page-text">
                                    국가지원 가능! <br />
                                    대만 정부, 교육부 및 <br class="d-none d-lg-block" />
                                    야구계 지원 가능
                                </p>
                            </num-item>
                        </v-col>
                    </v-row>
                </div>

                <h5 class="tit--sm font-weight-bold mb-10 mb-lg-26">이용내역</h5>

                <div class="pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4">
                    <div class="d-lg-flex align-center mb-12 mb-lg-20">
                        <p class="w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            2020
                        </p>
                        <p class="color-grey page-text">
                            키움히어로즈 스프링 캠프
                        </p>
                    </div>
                    <div class="d-lg-flex align-center mb-12 mb-lg-20">
                        <p class="w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            2019
                        </p>
                        <p class="color-grey page-text">
                            KT위즈 마무리 캠프 (유소년 견학 캠프 동시 진행)
                        </p>
                    </div>
                    <div class="d-lg-flex align-center mb-12 mb-lg-20">
                        <p class="w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            2018~2019
                        </p>
                        <p class="color-grey page-text">
                            롯데자이언츠 1군 스프링 캠프
                        </p>
                    </div>
                    <div class="d-lg-flex align-center mb-12 mb-lg-20">
                        <p class="w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            2016~2019
                        </p>
                        <p class="color-grey page-text">
                            롯데자이언츠 2군 스프링 캠프
                        </p>
                    </div>
                    <div class="d-lg-flex align-center">
                        <p class="w-100px w-lg-140px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold">
                            2016~2017
                        </p>
                        <p class="color-grey page-text">
                            경기고등학교 전지훈련
                        </p>
                    </div>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
import NumItem from "@/components/client/ko/sub/sub-num-item.vue";
import SlidePrimary from "@/components/client/ko/sub/sub-slide-primary.vue";
import CenterTop from "@/components/client/ko/sub/sub-center-top.vue";
import IconPrimary from "@/components/client/ko/sub/sub-icon-primary.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
        NumItem,
        SlidePrimary,
        CenterTop,
        IconPrimary,
    },
};
</script>

<style lang="scss" scoped></style>
