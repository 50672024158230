<template>
    <div v-if="className == 'sub-tab-wrap'" class="mt-lg-n60">
        <template v-for="item in gnbs">
            <ul class="tab tab--block" :key="item.title" v-if="item.title == sh">
                <li v-for="child in item.children" :key="child.title" class="tab__li" :class="{ active: tabActive == child.title }">
                    <a :href="child.path" class="tab__btn"
                        ><span>{{ child.title }}</span></a
                    >
                </li>
            </ul>
        </template>
    </div>
    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnbs">
            <li v-if="!item.children" :class="className + '__li'" :key="item.title">
                <router-link :to="item.path" :class="className + '__link'"
                    ><h2>{{ item.title }}</h2></router-link
                >
            </li>

            <li v-else :class="className + '__li'" :key="item.title">
                <div v-if="isMobile" :class="className + '__link gnb__link--toggle'">
                    <h2>{{ item.title }}</h2>
                </div>
                <router-link v-else :to="item.path" :class="className + '__link gnb__link--toggle'"
                    ><h2>{{ item.title }}</h2></router-link
                >
                <ul :class="className + '__sub'">
                    <li v-for="child in item.children" :key="child.title">
                        <router-link :to="child.path">{{ child.title }}</router-link>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className: String,
        indexNum: String,
        sh: String,
        tabActive: String,
    },
    data: function() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs: [
                {
                    title: "U the STAR",
                    path: "/about/notice",
                    children: [
                        {
                            title: "공지사항",
                            path: "/about/notice",
                        },
                        {
                            title: "재단소개",
                            path: "/about/business",
                        },
                        {
                            title: "활동사진",
                            path: "/about/gallery",
                        },
                    ],
                },
                {
                    title: "시설 및 참여",
                    path: "/center/square",
                    children: [
                        {
                            title: "시설소개",
                            path: "/center/square",
                        },
                        {
                            title: "코칭스탭",
                            path: "/center/staff1",
                        },
                        {
                            title: "프로그램 안내",
                            path: "/center/program",
                        },
                    ],
                },
                {
                    title: "문의하기",
                    path: "/inquiry",
                },
                {
                    title: "기부 및 후원하기",
                    path: "/donation/donation",
                    children: [
                        {
                            title: "기부 및 후원",
                            path: "/donation/donation",
                        },
                        // {
                        //     title: "유쾌한 상회",
                        //     path: "/donation/firm",
                        // },
                        // {
                        //     title: "[연.어.다.음] 캠페인",
                        //     path: "/campaign/salmon-campaign-donation",
                        // },
                    ],
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted: function() {},
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>
