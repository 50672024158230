var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.className + ' fade-slide fade-slide' + _vm.slideNum
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._t("default")], 2)]), _c('div', {
    staticClass: "pagination"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }