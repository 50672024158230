var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "num-item w-100 h-100 pt-20 pt-lg-30"
  }, [_c('div', {
    staticClass: "num w-40px w-lg-60px h-40px h-lg-60px bg-point rounded text-center d-flex align-center justify-center"
  }, [_c('h5', {
    staticClass: "tit--sm color-white text-center font-weight-bold"
  }, [_vm._v(_vm._s(_vm.num))])]), _c('div', {
    staticClass: "border bg-white border-lg-2 border-color-point w-100 h-100 d-flex flex-column align-center px-20 px-md-26 pt-40 pt-md-50 pt-lg-64 pb-20 pb-md-30 pb-lg-44 text-center"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }