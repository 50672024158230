var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "시설 및 참여",
      "tabActive": "시설소개",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "시설소개",
      "tabActive": "1"
    }
  }), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" 스퀘어 ")])]), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('center-top', {
    attrs: {
      "sc1": "‘블루스폿 스퀘어 (BLUESPOT SQUARE)’는 더 많은 아이들의 또 다른 시작을 의미합니다.",
      "sc2": "지금까지 내가 경험할 수 없었던 스포츠를 안전하고 친환경적인 장소에서 함께 합니다."
    }
  }, [_c('slide-primary', {
    attrs: {
      "slideNum": "1",
      "className": "pagination-top"
    }
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img1.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img2.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img3.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img5.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img6.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/square/s1-img7.jpg",
      "alt": ""
    }
  })])])], 1)], 1), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "01"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/square/s1-icon1.png",
      "maxWidth": "48"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 청라 유일의"), _c('br'), _vm._v(" 실내(야구,축구)"), _c('br'), _vm._v(" 레슨장 완비 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "02"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/square/s1-icon2.png",
      "maxWidth": "49"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 35mm의"), _c('br'), _vm._v(" 섬세하고 안전한"), _c('br'), _vm._v(" 실내 잔디 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "03"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/square/s1-icon3.png",
      "maxWidth": "31"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 주차걱정 NO!"), _c('br'), _vm._v(" 주차타워 내"), _c('br'), _vm._v(" 체육관 입점 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "04"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/square/s1-icon4.png",
      "maxWidth": "54"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 미세먼지 걱정 NO!"), _c('br'), _vm._v(" 날씨로부터 자유로운"), _c('br'), _vm._v(" 운동 가능 ")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mb-50 mb-lg-74 w-100"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/center/square/map.jpg"
    }
  })], 1), _c('h5', {
    staticClass: "tit--sm font-weight-bold mb-10 mb-lg-26"
  }, [_vm._v("찾아 오시는 길")]), _c('div', {
    staticClass: "pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4"
  }, [_c('div', {
    staticClass: "d-lg-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 주소 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" 22766 인천시 서구 보석로 12번안길 1, 1층 (청라주차타워) ")])]), _c('div', {
    staticClass: "d-lg-flex align-center"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 대중교통 ")]), _c('div', {
    staticClass: "d-lg-flex align-center page-text"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14"
  }, [_vm._v(" 버스 ")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('p', {
    staticClass: "d-flex color-grey"
  }, [_vm._v(" 심곡천 하차 (간선 7), 자이더테라스 1단지 정류장 (간선 7, 간선 44, 인천e음84) ")])])])])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }