var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "U the STAR",
      "tabActive": "재단소개",
      "bg": "/images/sub/visual/sv1.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "재단소개",
      "tabActive": "1"
    }
  }), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" 사업 운영 방향 ")])]), _c('v-row', [_c('v-col', {
    staticClass: "mb-10 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "01"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v(" 아동 청소년 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 적극 지원 및 후원 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 건강한 사회 적응과 정착을 위한 참여 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 체육 지원 활동의 일환으로 스포츠 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 봉사활동을 전개하며 특히, 아동 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 청소년 차상위 계층에 대한 적극적인 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 체육 봉사활동을 지원 및 후원한다. ")])])], 1), _c('v-col', {
    staticClass: "mb-10 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "02"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v(" 전문 체육인 재취업 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 양성 도모 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 다양한 종목의 은퇴 선수 출신 전문 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 스포츠 지도자를 배양하여 각종 스포츠 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 봉사활동 및 스포츠 지도자로서 새로운 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 도전을 할 수 있도록 적극 지원 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 후원한다. ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "03"
    }
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold color-point mb-6 mb-lg-14"
  }, [_vm._v(" 국내외 스포츠 생활화 및 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 생활 스포츠 문화 주도 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 다양한 그룹, 종목별 체육 활동을 통해 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 공정한 참여의 기회를 마련하고, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 국내외 다양한 스포츠 교류를 통해 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 스포츠 대중화 및 K-스포츠 문화의 위상을 높인다. ")])])], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "section bg-image",
    staticStyle: {
      "background-image": "url(/images/sub/about/business/s2-bg.jpg)"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold color-white"
  }, [_vm._v(" 사업 내용 ")])]), _c('business-item', {
    staticClass: "mb-24",
    attrs: {
      "num": "01",
      "tit": "차상위계층의 스포츠 활동 참여 확대 지원"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text"
  }, [_vm._v(" · 스포츠활동이 필요한 차상위(취약, 다문화, 한부모, 저소득, 탈북민 등) 계층을 대상으로 실질적인 체육활동 지원 방안 강구 ")])]), _c('business-item', {
    staticClass: "mb-24",
    attrs: {
      "num": "02",
      "tit": "국내 프로 선수 출신의 전문교사 양성 및 선수 발굴"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 현직(전직) 프로 선수 경력 7년차 이상 또는 전문 스포츠 지도자 자격증을 겸비한 전문 교사 양성 ")]), _c('p', {
    staticClass: "page-text dot-text"
  }, [_vm._v(" · 현역 프로 스포츠 경력자의 경험과 인성을 키우는 교육을 통한 선수 발굴 ")])]), _c('business-item', {
    staticClass: "mb-24",
    attrs: {
      "num": "03",
      "tit": "국내 프로 스포츠 유망 선수 양성 및 지원 (에이전트 및 매니지먼트)"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 스포츠에서 유망주가 될 수 있는 기회제공 ")]), _c('p', {
    staticClass: "page-text dot-text"
  }, [_vm._v(" · 취미에서 시작해 프로 스포츠 선수로 성장 기회 제공 ")])]), _c('business-item', {
    staticClass: "mb-24",
    attrs: {
      "num": "04",
      "tit": "국내 스포츠 인재의 국제대회 참가 및 국내외 전지훈련 지원"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 국제 대회 설립을 목표로 대회 전지훈련 상시화 ")]), _c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 각종 국내 대회 및 국제 대회 지역 유치 토대 마련 ")]), _c('p', {
    staticClass: "page-text dot-text"
  }, [_vm._v(" · 지역 스포츠 경제활동 극대화 및 한류관광 목표로 대회 개최 ")])]), _c('business-item', {
    staticClass: "mb-24",
    attrs: {
      "num": "05",
      "tit": "국외 선수 초청 및 국제 스포츠 교류를 통한 K-스포츠 위상 강화"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 국내 지역대회 개최 시 국외 선수 참가 유치 (지역경제 활성화 방안 마련) ")]), _c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 세계 청소년들과 함께 할 수 있는 스포츠 대회 유치 ")]), _c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 겨울 스포츠가 없는 국가의 단체 초청을 통한 참여하는 스포츠 마련 ")]), _c('p', {
    staticClass: "page-text dot-text"
  }, [_vm._v(" · 스포츠 문화 전파 및 새로운 K-스포츠 문화 주도 ")])]), _c('business-item', {
    attrs: {
      "num": "06",
      "tit": "국내외 친환경 체육시설 설치 지원 사업"
    }
  }, [_c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 친환경 체육시설에 맞춤 체육활동 프로그램 개발 ")]), _c('p', {
    staticClass: "page-text dot-text mb-2 mb-lg-8"
  }, [_vm._v(" · 기존의 놀이터를 친환경 체육시설로 전환 설계 및 지원 ")]), _c('p', {
    staticClass: "page-text dot-text"
  }, [_vm._v(" · 스포츠 컨텐츠 개발 및 체육시설 개발, 운영, 관리 ")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }