var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "U the STAR",
      "tabActive": "재단소개",
      "bg": "/images/sub/visual/sv1.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "재단소개",
      "tabActive": "3"
    }
  }), _c('div', {
    staticClass: "top-image mt-lg-80"
  }, [_c('v-row', {
    staticClass: "flex-column-reverse flex-md-row"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9",
      "lg": "7"
    }
  }, [_c('h3', {
    staticClass: "tit mb-26 mb-lg-44"
  }, [_vm._v(" 안녕하십니까? "), _c('br'), _vm._v(" 재단법인 유더스타스포츠 "), _c('br'), _vm._v(" 이사장 유인국 인사드립니다. ")]), _c('p', {
    staticClass: "page-text color-grey mb-4 mb-lg-22"
  }, [_vm._v(" 개인적인 포부와 희망을 품고 한 우물만 보고 사업에만 몰두하다 이 사회를 위해 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 무엇인가 봉사를 하고 싶은 마음에 서울 강서구 지역내에서 체육회 이사회장, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 장학회이사, 민주평화통일자문회의 협의회장까지 여러 가지 직책을 맡으면서 나름의 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 소신을 가지고 최선을 다해 지역에 봉사하고 활동해 왔습니다. ")]), _c('p', {
    staticClass: "page-text color-grey mb-4 mb-lg-22"
  }, [_vm._v(" 그 중 소싯적부터 현재까지도 즐기고 있는 여러가지 체육 활동이 저 뿐만 아니라 이 지역, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 사회, 국가에 건강한 도움이 될 수 있을 것 같다는 생각으로 체육 활동 및 시설을 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 공유해야겠다는 굳은 마음으로 체육시설 재단법인을 설립하게 되었습니다. ")]), _c('p', {
    staticClass: "page-text color-grey mb-4 mb-lg-22"
  }, [_vm._v(" 우리 체육재단은 엘리트 체육인의 양성 및 선수 육성의 목적과 올바른 인성을 갖춘 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 체육인으로 성장 할 수 있도록 지원하고, 인기 스포츠를 쉽게 접할 수 없었던 차상위계층을 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 위해 “같이 할 수 있는 스포츠“, ”함께 할 수 있는 스포츠”를 만들어 보고자 합니다. ")]), _c('p', {
    staticClass: "page-text color-grey mb-4 mb-lg-22"
  }, [_vm._v(" 그런 재단이 될 수 있도록 우리 재단을 응원해주시고, 우리 재단시설을 마음껏 이용해 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 주셔서 많은 사랑과 관심을 가져 주시길 바랍니다. ")]), _c('p', {
    staticClass: "page-text color-grey mb-4 mb-lg-22"
  }, [_vm._v(" 또한, 대중에게 더 가깝게 다가가기 위해 우리 재단이 할 수 있는 것을 고민한 끝에 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" [재단법인 유더스타스포츠]는 “블루스폿 필드”와 \"블루팟 그라운드\" 2개의 운동장을 준비 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 및 개방하여 다양한 체육활동을 진행 중에 있으며, 이것을 통해 우리 재단의 목표를 실현해 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 나가고 있습니다. ")]), _c('p', {
    staticClass: "page-text color-grey mb-12 mb-lg-0"
  }, [_vm._v(" 가정, 학교, 기업, 멤버쉽 모임까지 다양한 스포츠를 체험하고 싶은 분들을 모시고 즐겁고 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 재미있는 스포츠의 세계로 여러분을 초대하고 싶습니다. 감사합니다. ")]), _c('div', {
    staticClass: "d-block d-lg-none"
  }, [_c('p', {
    staticClass: "page-text color-point"
  }, [_vm._v(" 재단법인 유더스타스포츠 이사장 ")]), _c('h5', {
    staticClass: "tit--sm font-weight-bold"
  }, [_vm._v(" 유인국 드림 ")])])]), _c('v-col', {
    staticClass: "d-flex justify-lg-end",
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "5"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-space-between"
  }, [_c('div', [_c('img', {
    staticClass: "d-block mb-14 mb-lg-30",
    attrs: {
      "src": "/images/sub/about/greeting/s1-img.jpg",
      "alt": ""
    }
  }), _c('img', {
    staticClass: "d-block",
    attrs: {
      "src": "/images/logo-light.png",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "d-none d-lg-block"
  }, [_c('p', {
    staticClass: "page-text color-point mb-10"
  }, [_vm._v(" 재단법인 유더스타스포츠 이사장 ")]), _c('h5', {
    staticClass: "tit--sm font-weight-bold"
  }, [_vm._v(" 유인국 드림 ")])])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }