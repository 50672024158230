<template>
    <client-page>

        <sub-visual
        sh="U the STAR"
        tabActive="활동사진"
        bg="/images/sub/visual/sv1.jpg">
        </sub-visual>
        
        <section class="section">
            <v-container>
                <gallery-list v-if="!$route.params._board"></gallery-list>
                <gallery-view v-else></gallery-view>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import GalleryList from "@/components/client/ko/board/skin/gallery/list.vue"
import GalleryView from "@/components/client/ko/board/skin/gallery/view.vue"
export default {
    components: {
        ClientPage,
        SubVisual,
        GalleryView,
        GalleryList
    },
};
</script>

<style lang="scss" scoped>

</style>