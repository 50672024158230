<template>
    <v-tabs v-if="baseball" grow hide-slider class="sub-staff-tab">
        <v-tab to="/center/staff1">현남수 총감독</v-tab>
        <v-tab to="/center/staff2">박상혁 감독</v-tab>
        <v-tab to="/center/staff3">김원진 수석 코치</v-tab>
        <v-tab to="/center/staff4">홍승우 타격 코치</v-tab>
    </v-tabs>

    <v-tabs v-else grow hide-slider class="sub-staff-tab">
        <v-tab to="/center/staff5">이정훈 축구 코치</v-tab>
        <v-tab to="/center/staff6">김성민 축구 코치</v-tab>
    </v-tabs>
</template>

<script>
export default {
    props: {
        baseball: { type: Boolean },
    },
};
</script>

<style lang="scss" scoped>
.sub-staff-tab {
    margin-bottom: 30px;
    ::v-deep {
        .v-tabs-bar {
            height: auto !important;
        }
        .v-tabs-bar__content {
            flex-wrap: wrap;
        }
    }
    .v-tab {
        width: 50%;
        height: 44px;
        &::after {
            display: none;
        }
    }
}
@media (min-width: 1024px) {
    .sub-staff-tab {
        margin-bottom: 50px;
        .v-tab {
            width: auto;
            height: 50px;
        }
    }
}
</style>