<template>
    <div>
        <div class="business-item pa-20 pa-md-30 py-lg-44 px-lg-50 d-flex">
            <h5 class="tit--sm w-40px mr-10 font-weight-bold color-point">
                {{num}}
            </h5>
            <div>
                <h5 class="tit--sm font-weight-bold mb-8 mb-lg-14">
                    {{tit}}
                </h5>
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    props : {
        num : {type :String, default : ""},
        tit : {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	// mounted() {
	// 	this.init();
	// },
	// methods : {
	// 	init : function() {
    //         setTimeout(() => {
    //             AOS.init({
    //                 once: false,
    //                 offset: 240,
    //                 duration: 500,
    //                 throttleDelay : 100,
    //                 easing : 'ease-in-out',
    //                 anchorPlacement : 'top-center',
    //             });
    //         }, 100);
	// 	}
	// },
}
</script>

<style scoped>
.business-item{
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    position: relative;
}

.business-item::after{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid var(--v-primary-base);
    opacity: 0;
    visibility: hidden;
}

.business-item,
.business-item *{
    transition: 0.15s ease-out;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .business-item:hover{
        background-color: #fff;
    }

    .business-item:hover::after{
        opacity: 1;
        visibility: visible;
    }

    .business-item:hover h5{
        color: #333;
    }
    .business-item:hover p{
        color: #777;
    }
}
@media (min-width:1200px){
}

</style>