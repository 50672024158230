var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "mb-12 mb-lg-24"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "w-100 w-md-120px",
    attrs: {
      "placeholder": "선택",
      "items": _vm.filterItems,
      "persistent-placeholder": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "item-text": "text",
      "item-valuie": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 w-md-300px",
    attrs: {
      "disabled": _vm.filter.searchKey == null,
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요."
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--input w-100 min-w-md-100px",
    attrs: {
      "color": "secondary",
      "dense": ""
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('table', {
    staticClass: "board-list"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "d-none d-lg-table-cell",
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.number')))]), _c('th', {
    staticStyle: {
      "width": "54%"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.제목')))]), _c('th', {
    staticStyle: {
      "width": "14.5%"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.작성자')))]), _c('th', {
    staticStyle: {
      "width": "14.5%"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.작성일')))]), _c('th', {
    staticStyle: {
      "width": "8%"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.조회수')))])])]), _c('tbody', [_vm._l(_vm.fixed, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")])], 1), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip board-list__tit"
    }, [_c('span', [_vm._v(_vm._s(notification.subject))])])]), _vm._m(0, true), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.viewCount) + " ")])]);
  }), _vm._l(_vm.notifications, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell",
      attrs: {
        "align": "center"
      }
    }, [_c('span', [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))])]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(notification.subject))])])]), _vm._m(1, true), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.viewCount) + " ")])]);
  })], 2)]), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "ellip"
  }, [_c('span', [_vm._v("관리자")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "ellip"
  }, [_c('span', [_vm._v("관리자")])])]);

}]

export { render, staticRenderFns }