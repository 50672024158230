var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "기부 및 후원하기",
      "tabActive": "기부 및 후원",
      "bg": "/images/sub/visual/sv4.jpg"
    }
  }), _c('section', {
    staticClass: "section--sm section--first"
  }, [_c('v-container', [_c('div', {
    staticClass: "mb-50 mb-lg-72"
  }, [_c('v-tabs', {
    staticClass: "tab-col-6",
    attrs: {
      "centered": "",
      "center-active": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v(" 지원대상 및 "), _c('br', {
    staticClass: "d-block d-sm-none"
  }), _vm._v("프로그램 안내 ")]), _c('v-tab', [_vm._v(" 신청절차 ")])], 1)], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('div', {
    staticClass: "pa-20 py-md-26 w-100 d-md-flex justify-center page-text--lg text-center",
    staticStyle: {
      "background-color": "#ffe4d9"
    }
  }, [_c('p', {
    staticClass: "color-point font-weight-bold mb-2 mb-md-0 mr-md-8 mr-lg-16"
  }, [_vm._v("지원대상")]), _c('p', [_vm._v("품행이 단정하고 타 학생 및 학교생활에 성실하고 모범이 되는 아동(청소년)")])]), _c('div', {
    staticClass: "pa-20 pa-md-30 px-lg-50 py-lg-60 w-100",
    staticStyle: {
      "background-color": "#fff8f6"
    }
  }, [_c('div', {
    staticClass: "mb-20 mb-lg-54"
  }, [_c('div', {
    staticClass: "d-flex align-center mb-6 mb-lg-18"
  }, [_c('div', {
    staticClass: "num d-flex align-center justify-center text-center bg-point color-white font-weight-bold page-text--sm mr-8 mr-lg-16"
  }, [_vm._v(" 01 ")]), _c('h5', {
    staticClass: "tit--sm font-weight-bold color-point"
  }, [_vm._v(" 취약계층 아동(청소년) 지원 ")])]), _c('div', {
    staticClass: "pl-38 pl-lg-52"
  }, [_c('h6', {
    staticClass: "page-text--lg font-weight-bold mb-4 mb-lg-10"
  }, [_vm._v(" 체육 활동 지원 프로그램 ")]), _c('p', {
    staticClass: "dot-text color-grey page-text"
  }, [_vm._v(" · 형편상 체육활동이 어려운 아동, 청소년들의 체육 및 체육 활동 시설 지원 ")]), _c('p', {
    staticClass: "dot-text color-grey page-text mb-6 mb-lg-22"
  }, [_vm._v(" · 민주화 운동 관련 국가유공자(상훈수여자) 및 애국지사 후손 등에게 스포츠 경기 관람 및 국내외 스포츠 캠프 참여등 체험 기회를 부여 ")]), _c('h6', {
    staticClass: "page-text--lg font-weight-bold mb-4 mb-lg-10"
  }, [_vm._v(" 외부기관 지원 장학금 프로그램 ")]), _c('p', {
    staticClass: "dot-text color-grey page-text"
  }, [_vm._v(" · 형편상 체육활동이 어려운 청소년에게 체육 활동 지원을 위해 체육 관련 외부 기관을 통해 지원 ")]), _c('p', {
    staticClass: "dot-text color-grey page-text"
  }, [_vm._v(" · 기타 장학금 : 위 내용 이외에 재단의 설립 목적(사업)에 부합되는 경우 (이사회의 의결을 거쳐 장학금 지급 검토) ")])])]), _c('div', [_c('div', {
    staticClass: "d-flex align-center mb-6 mb-lg-18"
  }, [_c('div', {
    staticClass: "num d-flex align-center justify-center text-center bg-point color-white font-weight-bold page-text--sm mr-8 mr-lg-16"
  }, [_vm._v(" 02 ")]), _c('h5', {
    staticClass: "tit--sm font-weight-bold color-point"
  }, [_vm._v(" 엘리트 유소년(선수) 지원 ")])]), _c('div', {
    staticClass: "pl-38 pl-lg-52"
  }, [_c('h6', {
    staticClass: "page-text--lg font-weight-bold mb-4 mb-lg-10"
  }, [_vm._v(" 특기개발 장학금 후원 프로그램 ")]), _c('p', {
    staticClass: "dot-text color-grey page-text"
  }, [_vm._v(" · 재능(엘리트)이 있는 아동(청소년)들의 특기 개발 지원. 실전 테스트 통한 재능 (초기)발굴 및 (향후)개발 가능 ")])])])])]), _c('v-tab-item', [_c('div', {
    staticClass: "pa-20 py-md-26 w-100 page-text--lg text-center",
    staticStyle: {
      "background-color": "#ffe4d9"
    }
  }, [_c('p', {
    staticClass: "color-point font-weight-bold"
  }, [_vm._v("장학생 지원자는 지정된 기일 내에 아래의 서류를 제출하여야 합니다.")])]), _c('div', {
    staticClass: "pa-20 pa-md-30 px-lg-50 py-lg-60 w-100 page-text",
    staticStyle: {
      "background-color": "#fff8f6"
    }
  }, [_c('p', {
    staticClass: "mb-2 mb-lg-8"
  }, [_vm._v(" 01. (장학금)지급 혹은 체육활동 지원 신청서(사진 첨부 장학금일 경우) ")]), _c('p', {
    staticClass: "mb-2 mb-lg-8"
  }, [_vm._v(" 02. 추천서(학교장 또는 지방자치단체장) ")]), _c('p', {
    staticClass: "mb-2 mb-lg-8"
  }, [_vm._v(" 03. 자기소개서 (혹은 단체소개서) ")]), _c('p', {
    staticClass: "mb-2 mb-lg-8"
  }, [_vm._v(" 04. 가족관계증명서 ")]), _c('p', {
    staticClass: "mb-2 mb-lg-8"
  }, [_vm._v(" 05. 주민등록등본 ")]), _c('p', {
    staticClass: "mb-2 mb-lg-8"
  }, [_vm._v(" 06. 장학금 지급 계좌 사본(보호자 또는 학교명의 계좌) ")]), _c('p', [_vm._v(" 07. 기타 장학생 선정을 위해 추가로 필요하다고 인정되는 서류 ")])])])], 1), _c('div', {
    staticClass: "mt-50 mt-lg-74"
  }, [_c('h5', {
    staticClass: "tit--sm font-weight-bold mb-10 mb-lg-26"
  }, [_vm._v(" 기부 및 후원 형태 ")]), _c('div', {
    staticClass: "w-100 border border-2 border-lg-4 border-color-light pa-20 pa-md-30 px-lg-10 py-lg-54"
  }, [_c('v-row', {
    staticClass: "mx-lg-0"
  }, [_c('v-col', {
    staticClass: "px-lg-40 px-xl-50 dona-item",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3",
      "xl": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold mb-2 mb-lg-14"
  }, [_vm._v(" 일반기부 ")]), _c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v(" 현금출자 / 일시불 형식 ")])]), _c('v-col', {
    staticClass: "px-lg-40 px-xl-50 dona-item",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3",
      "xl": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold mb-2 mb-lg-14"
  }, [_vm._v(" 재능기부 ")]), _c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v(" 멘토링 기부 / 프로선수 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 비시즌 멘토링 수업 ")])]), _c('v-col', {
    staticClass: "px-lg-40 px-xl-50 dona-item",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3",
      "xl": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold mb-2 mb-lg-14"
  }, [_vm._v(" 봉사활동기부 ")]), _c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v(" 병역시간, 봉사시간 / 병역 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 및 봉사활동 멘토링 수업 ")])]), _c('v-col', {
    staticClass: "px-lg-40 px-xl-50 dona-item",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3",
      "xl": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold mb-2 mb-lg-14"
  }, [_vm._v(" 용품후원 ")]), _c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v(" 용품업체 용품 지급 후원 / "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 의류 및 음료 스포츠 관련 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 장비 후원 ")])])], 1)], 1)])], 1)], 1), _c('section', {
    staticClass: "section--sm section--last"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" 기부 및 후원 신청 ")])])]), _c('form-mail')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }