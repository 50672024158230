<template>
    <client-page>
        <sub-visual
        sh="개인정보 제3자 제공 동의"
        bg="/images/sub/visual/sv1.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <p class="font-size-14 font-size-lg-18">
                    개인정보를 제공 받는자 : 사단법인 금융결제원, 금융기관, 통신사 <br><br>

                    개인정보를 제공 받는 자의 이용 목적 : CMS 출금 이체 서비스 제공 및 출금 동의 확인, 출금이체 신규등록 및 해지사실 통지 <br><br>

                    제공하는 개인정보의 항목 : 성명, 성별, 생년월일, 연락처, 은행, 예금주(명의자),계좌번호,(휴대)전화번호,E-mail,주소 <br><br>

                    개인정보를 제공 받는 자의 개인 정보 보유 및 이용기간 : CMS출금이체 서비스 제공 및 출금동의 확인 목적을 달성할때까지 신청자는 개인정보에 대해 금융결제원에 제공하는 것을 거부할 권리가 있으며, 거부 시 출금이체 신청이 거부 될수 있습니다.
                </p>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>
