var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "시설 및 참여",
      "tabActive": "시설소개",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "시설소개",
      "tabActive": "2"
    }
  }), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" 필드 ")])]), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('center-top', {
    attrs: {
      "sc1": "‘블루스폿 필드 (BLUE SPOT FIELD)’는 아이들의 완성 되어지는 과정을 우리가 함께 키워나가는 곳입니다.",
      "sc2": "지금까지 내가 경험할 수 없었던 스포츠를 안전하고 친환경적인 장소에서 함께 합니다."
    }
  }, [_c('slide-primary', {
    attrs: {
      "slideNum": "1",
      "className": "pagination-top"
    }
  }, [_c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/field/s1-img1.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/field/s1-img3.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/field/s1-img4.jpg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "swiper-slide"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": "/images/sub/center/field/s1-img5.jpg",
      "alt": ""
    }
  })])])], 1)], 1), _c('div', {
    staticClass: "mb-50 mb-lg-80"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "01"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/field/s1-icon1.png",
      "maxWidth": "53"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 모든 종목 레슨 최고 시설! "), _c('br'), _vm._v(" 실내,외 기능 다 갖춤. "), _c('br'), _vm._v(" 트레이닝 실(샤워실) 완비 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-lg-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "02"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/field/s1-icon2.png",
      "maxWidth": "31"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 접근성 최고! 서울시 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 강서구 발산역 3분 거리 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 주차시설 완비 ")])], 1)], 1), _c('v-col', {
    staticClass: "mb-10 mb-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "03"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/field/s1-icon3.png",
      "maxWidth": "49"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 20m 거리 롱패스도 거뜬! "), _c('br'), _vm._v(" 넓은 공간과 부상 방지를 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 위한 잔디설계 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "lg": "3"
    }
  }, [_c('num-item', {
    attrs: {
      "num": "04"
    }
  }, [_c('icon-primary', {
    attrs: {
      "className": "mb-10 mb-lg-20",
      "icon": "/images/sub/center/field/s1-icon4.png",
      "maxWidth": "40"
    }
  }), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 탁 트인 넓은 시야와 시원한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 타격감! 8m 높이의 안전 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 펜스로 위험 걱정 NO! ")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mb-50 mb-lg-74 w-100"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/center/field/map.jpg"
    }
  })], 1), _c('h5', {
    staticClass: "tit--sm font-weight-bold mb-10 mb-lg-26"
  }, [_vm._v("찾아 오시는 길")]), _c('div', {
    staticClass: "pa-20 pa-ma-30 pa-lg-50 border border-color-light border-2 border-lg-4"
  }, [_c('div', {
    staticClass: "d-lg-flex align-center mb-12 mb-lg-20"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 주소 ")]), _c('p', {
    staticClass: "color-grey page-text"
  }, [_vm._v(" 07591 서울시 강서구 공항대로 39길 7 (등촌동, 재단법인 유더스타스포츠) ")])]), _c('div', {
    staticClass: "d-lg-flex align-center"
  }, [_c('p', {
    staticClass: "w-60px w-lg-100px py-4 page-text text-center bg-secon color-white mb-2 mb-lg-0 mr-12 mr-lg-40 font-weight-bold"
  }, [_vm._v(" 대중교통 ")]), _c('div', {
    staticClass: "d-lg-flex align-center page-text"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14"
  }, [_vm._v(" 버스 ")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "dot mr-2 mr-lg-4",
    staticStyle: {
      "background-color": "#4b80ea"
    }
  }), _c('span', {
    staticClass: "dot mr-4 mr-lg-10",
    staticStyle: {
      "background-color": "#04aeab"
    }
  }), _c('p', {
    staticClass: "color-grey"
  }, [_vm._v(" ‘한국가스기술공사(중)’ 하차 ")])])]), _c('span', {
    staticClass: "line mx-22 d-none d-lg-block"
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "font-weight-bold w-40px w-lg-auto mr-4 mr-lg-14"
  }, [_vm._v(" 지하철 ")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "dot mr-4 mr-lg-10",
    staticStyle: {
      "background-color": "#833edb"
    }
  }), _c('p', {
    staticClass: "color-grey"
  }, [_vm._v(" 5호선 발산역 3,4번 출구 ")])])])])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }