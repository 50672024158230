<template>
    <div class="staff-top mt-lg-80">
        <slot/>
    </div>
</template>

<script>

import Swiper from "swiper/bundle";

export default{
    props : {
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                this.swiperA = new Swiper(".fade-slide1 .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: '.fade-slide1 .pagination',
                        clickable: true,
                    },
                });
                this.swiperB = new Swiper(".fade-slide2 .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: '.fade-slide2 .pagination',
                        clickable: true,
                    },
                });
            });
        },
    }
}
</script>

<style scoped>
.staff-top{
    position: relative;
    padding-top: 80px;
}
.staff-top>*{
    position: relative;
}
.staff-top::before{
    content: "";
    width: 100vw;
    height: 162px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: var(--v-secondary-base);
    background-image: url(/images/sub/center/staff/staff-bg.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 280px auto;
}
@media (min-width:576px){
        .staff-top::before{
            background-size: 400px auto;
        }
}
@media (min-width:768px){
    .staff-top{
        padding-top: 120px;
    }
    .staff-top h3{
        color: #fff;
    }
    .staff-top::before{
        height: 220px;
    }
}
@media (min-width:1024px){
    .staff-top{
        padding-top: 160px;
    }
    .staff-top::before{
        background-size: auto;
        height: 310px;
    }
}
@media (min-width:1200px){
}
</style>