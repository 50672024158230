var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "시설 및 참여",
      "tabActive": "코칭스탭",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('dept3-tab', {
    attrs: {
      "page": "코칭스탭",
      "tabActive": "1"
    }
  }), _c('sub-staff-tab', {
    attrs: {
      "baseball": ""
    }
  }), _c('staff-top', [_c('v-row', [_c('v-col', {
    staticClass: "mb-50 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/center/staff/staff-01.jpg",
      "alt": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "lg": "7",
      "offset-lg": "1"
    }
  }, [_c('div', {
    staticClass: "w-100 d-sm-flex align-end justify-space-between mb-30 mb-md-70 mb-lg-110"
  }, [_c('div', [_c('p', {
    staticClass: "color-point page-text line-height-1 mb-6 mb-lg-20"
  }, [_vm._v("총감독")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold line-height-1 mr-8 mr-lg-20"
  }, [_vm._v("현남수")])])]), _c('v-btn', {
    staticClass: "min-w-180px min-w-lg-240px mt-20 mt-sm-0",
    attrs: {
      "to": "/inquiry",
      "large": "",
      "color": "primary"
    }
  }, [_c('span', {
    staticClass: "d-flex justify-space-between align-center w-100"
  }, [_vm._v(" 문의하기 "), _c('i', {
    staticClass: "icon icon-right-arrow"
  })])])], 1), _c('div', {
    staticClass: "mb-6 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v("학력 / 경력")])]), _c('v-col', {
    staticClass: "mt-n10 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "ml-lg-4"
  }, [_c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 부산고등학교")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 중앙대학교")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 롯데자이언츠")]), _c('p', {
    staticClass: "page-text dot-text color-grey"
  }, [_vm._v("· 청소년 ~ 성인 국가대표 선수")])])])], 1)], 1), _c('div', {
    staticClass: "mb-6 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v("상훈 / 자격증")])]), _c('v-col', {
    staticClass: "mt-n10 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "ml-lg-4"
  }, [_c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 1982 봉황대기 타격,타점왕")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· 1983 세계청소년야구대회 도루상")]), _c('p', {
    staticClass: "page-text dot-text color-grey"
  }, [_vm._v("· 1983,4 화랑대기 타격, 최다안타, 도루상")])])])], 1)], 1), _c('div', {
    staticClass: "mb-6 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v("지도")])]), _c('v-col', {
    staticClass: "mt-n10 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "ml-lg-4"
  }, [_c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· (전)감독자 협의회 회장")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· (전)리틀야구 연맹이사")]), _c('p', {
    staticClass: "page-text dot-text color-grey mb-2 mb-lg-8"
  }, [_vm._v("· (전)리틀야구 대표 선발위원장")]), _c('p', {
    staticClass: "page-text dot-text color-grey"
  }, [_vm._v("· (현)하남리틀감독")])])])], 1)], 1), _c('div', {
    staticClass: "mb-6 mb-lg-10"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('p', {
    staticClass: "page-text--lg font-weight-bold"
  }, [_vm._v("이메일")])]), _c('v-col', {
    staticClass: "mt-n10 mt-md-0",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "ml-lg-4"
  }, [_c('p', {
    staticClass: "page-text color-grey"
  }, [_vm._v("hanam@uthestar.org")])])])], 1)], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }