<template>
    <client-page>
        <sub-visual sh="시설 및 참여" tabActive="코칭스탭" bg="/images/sub/visual/sv2.jpg"> </sub-visual>

        <section class="section">
            <v-container>
                <dept3-tab page="코칭스탭" tabActive="2"></dept3-tab>
                <sub-staff-tab />

                <staff-top>
                    <v-row>
                        <v-col cols="12" md="4" class="mb-50 mb-md-0">
                            <!-- <slide-primary slideNum="1" className="pagination-under">
                                <div class="swiper-slide">
                                    <img src="" alt="" class="d-block w-100"> 
                                </div>
                                <div class="swiper-slide">
                                    <img src="" alt="" class="d-block w-100"> 
                                </div>
                                <div class="swiper-slide">
                                    <img src="" alt="" class="d-block w-100"> 
                                </div>
                            </slide-primary> -->
                            <v-img src="/images/sub/center/staff/staff-06.jpg" alt="" />
                        </v-col>
                        <v-col cols="12" md="8" lg="7" offset-lg="1">
                            <div class="w-100 d-sm-flex align-end justify-space-between mb-30 mb-md-70 mb-lg-110">
                                <div>
                                    <p class="color-point page-text line-height-1 mb-6 mb-lg-20">축구 코치</p>
                                    <div class="d-flex align-center">
                                        <h3 class="tit font-weight-bold line-height-1 mr-8 mr-lg-20">김성민</h3>
                                        <a href="https://www.instagram.com/uthestarsports/" target="_blank">
                                            <v-btn large icon class="instar">
                                                <i class="icon icon-insta"></i>
                                            </v-btn>
                                        </a>
                                    </div>
                                </div>
                                <v-btn to="/inquiry" large color="primary" class="min-w-180px min-w-lg-240px mt-20 mt-sm-0">
                                    <span class="d-flex justify-space-between align-center w-100">
                                        문의하기
                                        <i class="icon icon-right-arrow"></i>
                                    </span>
                                </v-btn>
                            </div>
                            <div class="mb-6 mb-lg-10">
                                <v-row class="row--small">
                                    <v-col cols="12" md="3">
                                        <p class="page-text--lg font-weight-bold">학력 / 경력</p>
                                    </v-col>
                                    <v-col cols="12" md="9" class="mt-n10 mt-md-0">
                                        <div class="ml-lg-4">
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 광희중학교</p>
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 통진고등학교</p>
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· Castilla La mancha UNIV.(입학)</p>
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 2017-18 ) CD Manchego ciudad real Juvenil A</p>
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 2018-19) Ciudad Real CF</p>
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 2018-19) CD Manchego Ciudad Real</p>
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 2019-2020) Leganes U21</p>
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 2019-2021) Rayo Ciudad Alcobendas</p>
                                            <p class="page-text dot-text color-grey">· 2021-2022) Villaverde San Andrés</p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mb-6 mb-lg-10">
                                <v-row class="row--small">
                                    <v-col cols="12" md="3">
                                        <p class="page-text--lg font-weight-bold">상훈 / 자격증</p>
                                    </v-col>
                                    <v-col cols="12" md="9" class="mt-n10 mt-md-0">
                                        <div class="ml-lg-4">
                                            <p class="page-text dot-text color-grey mb-2 mb-lg-8">· 스페인 축구 지도자 자격증 Nivel1</p>
                                            <p class="page-text dot-text color-grey">· 바르셀로나 비디오 분석가 양성과정 수료</p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- <div>
                                <v-row class="row--small">
                                    <v-col cols="12" md="3">
                                        <p class="page-text--lg font-weight-bold">지도</p>
                                    </v-col>
                                    <v-col cols="12" md="9" class="mt-n10 mt-md-0">
                                        <div class="ml-lg-4">
                                            <p class="page-text dot-text color-grey">· </p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div> -->
                            <div class="mb-6 mb-lg-10">
                                <v-row class="row--small">
                                    <v-col cols="12" md="3">
                                        <p class="page-text--lg font-weight-bold">이메일</p>
                                    </v-col>
                                    <v-col cols="12" md="9" class="mt-n10 mt-md-0">
                                        <div class="ml-lg-4">
                                            <p class="page-text color-grey">mininspain@uthestar.org</p>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </staff-top>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
import SlidePrimary from "@/components/client/ko/sub/sub-slide-primary.vue";
import StaffTop from "@/components/client/ko/sub/sub-staff-top.vue";

import SubStaffTab from "@/components/client/ko/sub/sub-staff-tab.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
        SlidePrimary,
        StaffTop,

        SubStaffTab,
    },
};
</script>

<style lang="scss" scoped>
</style>