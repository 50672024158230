<template>
    <client-page>

        <sub-visual
        sh="U the STAR"
        tabActive="재단소개"
        bg="/images/sub/visual/sv1.jpg">
        </sub-visual>

        <section class="section">
            <v-container>

                <dept3-tab page="재단소개" tabActive="1"></dept3-tab>

                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold">
                        사업 운영 방향
                    </h3>
                </div>

                <v-row>
                    <v-col cols="12" md="4" class="mb-10 mb-md-0">
                        <num-item num="01">
                            <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">
                                아동 청소년 <br class="d-none d-lg-block">
                                적극 지원 및 후원
                            </h5>
                            <p class="page-text">
                                건강한 사회 적응과 정착을 위한 참여 <br class="d-none d-lg-block">
                                체육 지원 활동의 일환으로 스포츠 <br class="d-none d-lg-block">
                                봉사활동을 전개하며 특히, 아동 <br class="d-none d-lg-block">
                                청소년 차상위 계층에 대한 적극적인 <br class="d-none d-lg-block">
                                체육 봉사활동을 지원 및 후원한다.
                            </p>
                        </num-item>
                    </v-col>
                    <v-col cols="12" md="4" class="mb-10 mb-md-0">
                        <num-item num="02">
                            <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">
                                전문 체육인 재취업 및 <br class="d-none d-lg-block">
                                양성 도모
                            </h5>
                            <p class="page-text">
                                다양한 종목의 은퇴 선수 출신 전문 <br class="d-none d-lg-block">
                                스포츠 지도자를 배양하여 각종 스포츠 <br class="d-none d-lg-block">
                                봉사활동 및 스포츠 지도자로서 새로운 <br class="d-none d-lg-block">
                                도전을 할 수 있도록 적극 지원 및 <br class="d-none d-lg-block">
                                후원한다.
                            </p>
                        </num-item>
                    </v-col>
                    <v-col cols="12" md="4">
                        <num-item num="03">
                            <h5 class="tit--sm font-weight-bold color-point mb-6 mb-lg-14">
                                국내외 스포츠 생활화 및 <br class="d-none d-lg-block">
                                생활 스포츠 문화 주도
                            </h5>
                            <p class="page-text">
                                다양한 그룹, 종목별 체육 활동을 통해 <br class="d-none d-lg-block">
                                공정한 참여의 기회를 마련하고, <br class="d-none d-lg-block">
                                국내외 다양한 스포츠 교류를 통해 <br class="d-none d-lg-block">
                                스포츠 대중화 및 K-스포츠 문화의
                                위상을 높인다.
                            </p>
                        </num-item>
                    </v-col>
                </v-row>

            </v-container>
        </section>

        <section class="section bg-image" style="background-image: url(/images/sub/about/business/s2-bg.jpg)">
            <v-container>

                <div class="tit-wrap">
                    <h3 class="tit font-weight-bold color-white">
                        사업 내용
                    </h3>
                </div>

                <business-item class="mb-24"
                num="01"
                tit="차상위계층의 스포츠 활동 참여 확대 지원">
                    <p class="page-text dot-text">
                        · 스포츠활동이 필요한 차상위(취약, 다문화, 한부모, 저소득, 탈북민 등) 계층을 대상으로 실질적인 체육활동 지원 방안 강구
                    </p>
                </business-item>

                <business-item class="mb-24"
                num="02"
                tit="국내 프로 선수 출신의 전문교사 양성 및 선수 발굴">
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 현직(전직) 프로 선수 경력 7년차 이상 또는 전문 스포츠 지도자 자격증을 겸비한 전문 교사 양성
                    </p>
                    <p class="page-text dot-text">
                        · 현역 프로 스포츠 경력자의 경험과 인성을 키우는 교육을 통한 선수 발굴
                    </p>
                </business-item>

                <business-item class="mb-24"
                num="03"
                tit="국내 프로 스포츠 유망 선수 양성 및 지원 (에이전트 및 매니지먼트)">
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 스포츠에서 유망주가 될 수 있는 기회제공
                    </p>
                    <p class="page-text dot-text">
                        · 취미에서 시작해 프로 스포츠 선수로 성장 기회 제공
                    </p>
                </business-item>

                <business-item class="mb-24"
                num="04"
                tit="국내 스포츠 인재의 국제대회 참가 및 국내외 전지훈련 지원">
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 국제 대회 설립을 목표로 대회 전지훈련 상시화
                    </p>
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 각종 국내 대회 및 국제 대회 지역 유치 토대 마련
                    </p>
                    <p class="page-text dot-text">
                        · 지역 스포츠 경제활동 극대화 및 한류관광 목표로 대회 개최
                    </p>
                </business-item>

                <business-item class="mb-24"
                num="05"
                tit="국외 선수 초청 및 국제 스포츠 교류를 통한 K-스포츠 위상 강화">
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 국내 지역대회 개최 시 국외 선수 참가 유치 (지역경제 활성화 방안 마련)
                    </p>
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 세계 청소년들과 함께 할 수 있는 스포츠 대회 유치
                    </p>
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 겨울 스포츠가 없는 국가의 단체 초청을 통한 참여하는 스포츠 마련
                    </p>
                    <p class="page-text dot-text">
                        · 스포츠 문화 전파 및 새로운 K-스포츠 문화 주도
                    </p>
                </business-item>

                <business-item
                num="06"
                tit="국내외 친환경 체육시설 설치 지원 사업">
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 친환경 체육시설에 맞춤 체육활동 프로그램 개발
                    </p>
                    <p class="page-text dot-text mb-2 mb-lg-8">
                        · 기존의 놀이터를 친환경 체육시설로 전환 설계 및 지원
                    </p>
                    <p class="page-text dot-text">
                        · 스포츠 컨텐츠 개발 및 체육시설 개발, 운영, 관리
                    </p>
                </business-item>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/ko/templates/ClientPage.vue";
import SubVisual from "@/components/client/ko/sub/sub-visual.vue";
import Dept3Tab from "@/components/client/ko/sub/dept3-tab.vue";
import NumItem from "@/components/client/ko/sub/sub-num-item.vue";
import BusinessItem from "@/components/client/ko/sub/sub-business-item.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        Dept3Tab,
        NumItem,
        BusinessItem,
    },
};
</script>

<style lang="scss" scoped>
</style>