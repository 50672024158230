var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('section', {
    staticClass: "sub-section"
  }, [_c('v-container', [_c('p', {
    staticClass: "text-right page-text--sm line-height-1 color-grey mb-4 mb-lg-8"
  }, [_c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력입니다")]), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("이름 (혹은 단체 기관명) "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.demo.name,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "name", $$v);
      },
      expression: "demo.name"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("이메일 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email1,
      callback: function ($$v) {
        _vm.email1 = $$v;
      },
      expression: "email1"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("@")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email2,
      callback: function ($$v) {
        _vm.email2 = $$v;
      },
      expression: "email2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-select', _vm._b({
    attrs: {
      "persistent-hint": "",
      "items": _vm.prependEmails,
      "item-text": "text",
      "item-value": "value",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.email2 = _vm.email3;
      }
    },
    model: {
      value: _vm.email3,
      callback: function ($$v) {
        _vm.email3 = $$v;
      },
      expression: "email3"
    }
  }, 'v-select', _vm.$attrs, false))], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("연락처 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.prependPhones,
      "placeholder": "연락처",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone1,
      callback: function ($$v) {
        _vm.phone1 = $$v;
      },
      expression: "phone1"
    }
  }, 'v-select', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone2,
      callback: function ($$v) {
        _vm.phone2 = $$v;
      },
      expression: "phone2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "maxlength": "4",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.phoneNumberJoin();
      }
    },
    model: {
      value: _vm.phone3,
      callback: function ($$v) {
        _vm.phone3 = $$v;
      },
      expression: "phone3"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("문의사항 "), _c('span', {
    staticStyle: {
      "color": "var(--v-primary-base)"
    }
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-row', {
    staticClass: "my-n2 my-lg-n6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "value": "취미,선수반 문의",
      "label": `취미,선수반 문의`
    },
    model: {
      value: _vm.demo.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "inquiry", $$v);
      },
      expression: "demo.inquiry"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "value": "진로체험 수업 문의",
      "label": `진로체험 수업 문의`
    },
    model: {
      value: _vm.demo.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "inquiry", $$v);
      },
      expression: "demo.inquiry"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "value": "대관 및 시설 문의",
      "label": `대관 및 시설 문의`
    },
    model: {
      value: _vm.demo.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "inquiry", $$v);
      },
      expression: "demo.inquiry"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "value": "레슨 문의",
      "label": `레슨 문의`
    },
    model: {
      value: _vm.demo.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "inquiry", $$v);
      },
      expression: "demo.inquiry"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "value": "대만 문의",
      "label": `대만 문의`
    },
    model: {
      value: _vm.demo.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "inquiry", $$v);
      },
      expression: "demo.inquiry"
    }
  })], 1), _c('v-col', {
    staticClass: "py-2 py-lg-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "value": "기타 문의",
      "label": `기타 문의`
    },
    model: {
      value: _vm.demo.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "inquiry", $$v);
      },
      expression: "demo.inquiry"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "table-style__row"
  }, [_c('div', {
    staticClass: "table-style__th"
  }, [_vm._v("내용")]), _c('div', {
    staticClass: "table-style__td"
  }, [_c('v-textarea', {
    attrs: {
      "rows": "11",
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.demo.content,
      callback: function ($$v) {
        _vm.$set(_vm.demo, "content", $$v);
      },
      expression: "demo.content"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex align-center justify-center border border-color-light border-2 border-lg-4 pa-20 pa-md-30 mt-30 mt-lg-40"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 수집·이용 동의",
      "value": "개인정보 수집·이용 동의",
      "hide-details": ""
    },
    model: {
      value: _vm.agree,
      callback: function ($$v) {
        _vm.agree = $$v;
      },
      expression: "agree"
    }
  }), _c('v-btn', {
    staticClass: "ml-8 ml-lg-14",
    attrs: {
      "to": "/privacy-policy",
      "color": "primary",
      "text": ""
    }
  }, [_vm._v("전문보기")])], 1), _c('div', {
    staticClass: "mt-50 mt-lg-80 text-center d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-200px",
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("문의하기")])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }